<template>
  <div class="doctor-personals-grp">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Informacion medica</h5>
      </div>
      <div class="card-body">
        <b-tabs content-class="">
          <b-tab title="Historial Medico" active>
            <MedicalHistoryProfileList :id="id" :bLoading="bLoading" :lstDataParam="lstHistoriasClinicas"/>
          </b-tab>
          <b-tab title="Consultas">
            <span>
              <ConsultationProfileList :id="id" :bLoading="bLoading" :lstDataParam="lstConsultas" />
            </span>
          </b-tab>
          <b-tab title="Citas">
            <span>
              <AppointmentProfileeList :id="id" :bLoading="bLoading" :lstDataParam="lstCitas" />
            </span>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "@/axios";
  import MedicalHistoryProfileList from "../medical-history/MedicalHistoryProfileList.vue";
  import ConsultationProfileList from "../consultation-patient/ConsultationProfileList.vue";
  import AppointmentProfileeList from "../appointment-patient/AppointmentProfileList.vue";

  export default {
    props: {
      id: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        bLoading: true,
        lstHistoriasClinicas: [],
        lstConsultas: [],
        lstCitas: [],
      };
    },
    components: {
      MedicalHistoryProfileList,
      ConsultationProfileList,
      AppointmentProfileeList,
    },
    created(){
      this.bLoading = true;
      this.loadData();
    },
    methods: {
      async loadData() {
        this.bLoading = true;
        try {
          const objResponse = await axios.get(`/pacientes/${this.id}/informacion-medica`);
          const objData = objResponse.data;
          this.lstHistoriasClinicas = objData.historiasClinicas.map(objHistoria => ({
            ...objHistoria,
            fecha_creacion: new Date(objHistoria.fecha_creacion).toISOString().split('T')[0],
            id_motivo_consulta: objHistoria.motivo_consulta?.nombre ?? '',
            cirugia: objHistoria.cirugia ? 'Si' : 'No',
          }));
          this.lstConsultas = objData.consultas.map(objConsulta => ({
            ...objConsulta,
            strTipoConsulta: objConsulta.tipo_consulta?.nombre ?? '',
            strTratamiento: objConsulta.tratamiento?.nombre ?? '',
            strFechaTratamiento: new Date(objConsulta.fecha_tratamiento).toISOString().split('T')[0],
          }));
          this.lstCitas = objData.citas.map(objCita => ({
            ...objCita,
            strCategoria: objCita.categoria_agenda?.nombre ?? '',
            strEstatus: objCita.estatus_agenda?.nombre ?? '',
            strFechaInicio: new Date(objCita.fecha_inicio).toISOString().split('T')[0],
            strHoraInicio: new Date(objCita.hora_inicio).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            
          }));
        } catch (error) {
          console.error(`Error al obtener historial medico: `, error);
        }
        this.bLoading = false;
      },
    },
    
  };
</script>