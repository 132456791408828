<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card card-table show-entire">
        <div class="card-body">
          <!-- Table Header -->
          <div class="page-table-header mb-2">
            <div class="row align-items-center">
              <div class="col">
                <div class="doctor-table-blk">
                  <h3></h3>
                  <div class="doctor-search-blk">
                    <div class="top-nav-search table-search-blk">
                      <form @submit.prevent="handleSearch">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Buscar consulta"
                          v-model="strSearchQuery"
                        />
                        <a class="btn"
                          ><img src="@/assets/img/icons/search-normal.svg" alt=""
                        /></a>
                      </form>
                    </div>
                    <div class="add-group">
                      <router-link
                        :to="{ name: 'add-appointment' }"
                        class="btn btn-primary add-pluss ms-2">
                        <img src="@/assets/img/icons/plus.svg" alt=""/>
                      </router-link>
                      <a
                        href="javascript:;"
                        class="btn btn-primary doctor-refresh ms-2"
                        @click="getData"
                        ><img src="@/assets/img/icons/re-fresh.svg" alt=""
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Table Header -->

          <!-- Table -->
          <div class="table-responsive">
            <a-table
              class="table border-0 custom-table comman-table datatable mb-0"
              :columns="lstColumns"
              :dataSource="handleFilterData"
              :rowSelection="handleRowSelection"
              :rowKey="record => record.id_agenda"
              :pagination="{ pageSize: 15 }"
              :loading="bSpinner">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'id_agenda'">
                  <div class="profile-image">
                    <!--router-link :to="{ name: 'add-appointment-patient', params: { id: record.id_paciente, parentId: record.id_agenda } }">
                      
                    </router-link-->
                    APPT-#{{ record.id_agenda }}
                  </div>
                </template>
                <template v-if="column.key === 'id_paciente'">
                  <div class="profile-image">
                    <router-link :to="{ name: 'patient-profile', params: { id: record.id_paciente } }">
                      {{ record.strPaciente }}
                    </router-link>
                  </div>
                </template>
                <template v-if="column.key === 'strEstatus'">
                  <div :class="record.strClass">
                    {{ record.strEstatus }}
                  </div>
                </template>
                <template v-else-if="column.key === 'action'">
                  <div class="text-end">
                    <div class="dropdown dropdown-action">
                      <a
                        href="javascript:;"
                        class="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-ellipsis-v"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link  :to="{ name: 'edit-appointment-patient', params: { id: record.id_paciente, recordId: record.id_agenda } }" 
                            class="dropdown-item" >
                            <i class="fa-solid fa-pen m-r-5"></i>
                              Editar</router-link>
                        <!--router-link :to="{ name: 'edit-consultation', params: {id: record.id_paciente, parentId: record.id_historia_clinica, recordId: record.id_consulta}}" 
                          class="dropdown-item" >
                          <i class="fa-solid fa-pen-to-square m-r-5"></i>
                            Editar</router-link-->
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
          <!-- /Table -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@/axios';

function createColumns(lstColumns) {
  return lstColumns.map((objColumn) => ({
    title: objColumn.strTitle,
    dataIndex: objColumn.strDataIndex,
    key: objColumn.strKey || objColumn.strDataIndex,
    sorter: objColumn.bSorter !== false ? {
      compare: (a, b) => {
        const aValue = a[objColumn.strDataIndex] ? String(a[objColumn.strDataIndex]).toLowerCase() : '';
        const bValue = b[objColumn.strDataIndex] ? String(b[objColumn.strDataIndex]).toLowerCase() : '';
        return aValue > bValue ? -1 : bValue > aValue ? 1 : 0;
      },
    } : undefined,
    class: objColumn.strClass || "",
  }));
}

const lstStatus = [
      { label: 'Nuevo', value: 'Nuevo', class: 'custom-badge status-blue' },
      { label: 'Pendiente', value: 'Pendiente', class: 'custom-badge status-orange' },
      { label: 'Confirmada', value: 'Confirmada', class: 'custom-badge status-blue' },
      { label: 'Completada', value: 'Completada', class: 'custom-badge status-green' },
      { label: 'Cancelada', value: 'Cancelada', class: 'custom-badge status-red' },
    ];

const lstColumnsToCreate = [
  { strTitle: "Cita", strDataIndex: "id_agenda", strKey: "id_agenda" },
  { strTitle: "Paciente", strDataIndex: "id_paciente", strKey: "id_paciente",},
  { strTitle: "Fecha", strDataIndex: "strFechaInicio", strKey: "strFechaInicio" },
  { strTitle: "Hora", strDataIndex: "strHoraInicio", strKey: "strHoraInicio" },
  { strTitle: "Categoria", strDataIndex: "strCategoria", strKey: "strCategoria", bFilter: true },
  { strTitle: "Estatus", strDataIndex: "strEstatus", strKey: "strEstatus", bFilter: true },
  { strTitle: "", strDataIndex: "action", strKey: "action", sorter: false, strClass: "text-end" },
];

const lstColumns = createColumns(lstColumnsToCreate);
export default {
  emits: ["update:modelValue", "select"],
  data() {
    return {
      bSpinner: false,
      strSearchQuery: "",
      lstData: [],
      lstColumns,
      lstRowSelected: [],
      lstStatus: lstStatus,
    };
  },
  computed: {
    handleFilterData() {
      if (!this.strSearchQuery) return this.lstData;
      const query = this.strSearchQuery.toLowerCase();
      return this.lstData.filter(paciente =>
        paciente.strTipoConsulta.toLowerCase().includes(query)
      );
    },
    handleRowSelection(){
      return {
        selectedRowKeys: this.lstRowSelected,
        onChange: this.handleRowSelectionChange,
      }
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    async loadData() {
      this.bSpinner = true;
      try {
        const objResponse = await axios.get('/agendas/dashboard/hoy');
        this.lstData = objResponse.data.map(objData => ({
          ...objData,
          strPaciente: `${objData.paciente?.nombre} ${objData.paciente?.apellido_paterno} ${objData.paciente?.apellido_materno}`,
          strFechaInicio: new Date(objData.fecha_inicio).toISOString().split('T')[0],
          strHoraInicio: new Date(objData.hora_inicio).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          strCategoria: objData.categoria_agenda?.nombre ?? 'No definida',
          strEstatus: objData.estatus_agenda?.nombre ?? 'No definido',
          strClass: lstStatus.find(objStatus => objStatus.label === objData.estatus_agenda.nombre)?.class ?? 'custom-badge status-grey',
        }));
      } catch (error) {
        console.error(`Error al obtener Consultas: `, error);
      }
      this.bSpinner = false;
    },
    handleRowSelectionChange(selectedRowKeys) {
      this.lstRowSelected = selectedRowKeys;
    },
    handleSearch() {
      // Este método se llama automáticamente debido a la propiedad computada `filteredData`
    },
  }
};
</script>