<template>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="card bg-white">
            <div class="card-header">
              <h5 class="card-title">Configuración Medica</h5>
            </div>
            <div class="card-body">
              <b-tabs content-class="">
                <b-tab title="Tipo Consulta" active>
                  <AppointmentTypeList />
                </b-tab>
                <b-tab title="Motivo Consulta">
                  <span>
                    <ReasonVisitList />
                  </span>
                </b-tab>
                <b-tab title="Tratamiento">
                  <span>
                    <TreatmentList />
                  </span>
                </b-tab>
                <b-tab title="Patologia">
                  <span>
                    <PatologyList />
                  </span>
                </b-tab>
                <b-tab title="Alergia">
                  <span>
                    <AllergyList />
                  </span>
                </b-tab>
                <b-tab title="Habito">
                  <span>
                    <HabitList />
                  </span>
                </b-tab>
                <b-tab title="Anatomia">
                  <span>
                    <AnatomyList />
                  </span>
                </b-tab>
                <b-tab title="Extremidad">
                  <span>
                    <LimbList />
                  </span>
                </b-tab>
                <b-tab title="Prueba Fisica">
                  <span>
                    <TestList />
                  </span>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AllergyList from '../allergy/AllergyList.vue';
import AnatomyList from '../anatomy/AnatomyList.vue';
import AppointmentTypeList from '../appointment-type/AppointmentTypeList.vue';
import HabitList from '../habit/HabitList.vue';
import LimbList from '../limb/LimbList.vue';
import PatologyList from '../patology/PatologyList.vue';
import ReasonVisitList from '../reason-visit/ReasonVisitList.vue';
import TestList from '../test/TestList.vue';
import TreatmentList from '../treatment/TreatmentList.vue';

export default {
  data() {
    return {};
  },
  components: {
    AllergyList,
    AnatomyList,
    AppointmentTypeList,
    HabitList,
    LimbList,
    PatologyList,
    ReasonVisitList,
    TestList,
    TreatmentList,

  },
};
</script>
