<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="title" :text="text" path="admin-dashboard" />

      <div class="good-morning-blk">
        <div class="row">
          <div class="col-md-6">
            <div class="morning-user">
              <h2>Buen dia, <span>{{ objDataUser.nombre }} {{ objDataUser.apellido_paterno }}</span></h2>
              <p>Ten un buen dia de trabajo</p>
            </div>
          </div>
          <div class="col-md-6 position-blk">
            <div class="morning-img">
              <img src="@/assets/img/morning-img-01.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <div class="dash-boxs comman-flex-center">
              <img src="@/assets/img/icons/calendar.svg" alt="" />
            </div>
            <div class="dash-content dash-count">
              <h4>Citas del Mes</h4>
              <h2>
                <vue3-autocounter
                  class="counter-up"
                  ref="counter"
                  :startAmount="0"
                  :delay="3"
                  :endAmount="iTotalCitas"
                  :duration="iDurationCounter"
                  separator=","
                  :autoinit="true"
                />
              </h2>
              <!--p>
                <span class="passive-view"
                  ><i class="feather-arrow-up-right me-1"></i>40%</span
                >
                vs last month
              </p-->
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <div class="dash-boxs comman-flex-center">
              <img src="@/assets/img/icons/profile-add.svg" alt="" />
            </div>
            <div class="dash-content dash-count">
              <h4>Pacientes del mes</h4>
              <h2>
                <vue3-autocounter
                  class="counter-up"
                  ref="counter"
                  :startAmount="0"
                  :delay="3"
                  :endAmount="iTotalPacientes"
                  :duration="iDurationCounter"
                  separator=","
                  :autoinit="true"
                />
              </h2>
              <!--p>
                <span class="passive-view"
                  ><i class="feather-arrow-up-right me-1"></i>20%</span
                >
                vs last month
              </p-->
            </div>
          </div>
        </div>
        <!--div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <div class="dash-boxs comman-flex-center">
              <img src="@/assets/img/icons/scissor.svg" alt="" />
            </div>
            <div class="dash-content dash-count">
              <h4>Operations</h4>
              <h2>
                <vue3-autocounter
                  class="counter-up"
                  ref="counter"
                  :startAmount="0"
                  :delay="3"
                  :endAmount="56"
                  :duration="5"
                  separator=","
                  :autoinit="true"
                />
              </h2>
              <p>
                <span class="negative-view"
                  ><i class="feather-arrow-down-right me-1"></i>15%</span
                >
                vs last month
              </p>
            </div>
          </div>
        </div-->
        <!--div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <div class="dash-boxs comman-flex-center">
              <img src="@/assets/img/icons/empty-wallet.svg" alt="" />
            </div>
            <div class="dash-content dash-count">
              <h4>Earnings</h4>
              <h2>
                $
                <vue3-autocounter
                  class="counter-up"
                  ref="counter"
                  :startAmount="0"
                  :delay="3"
                  :endAmount="20250"
                  :duration="5"
                  separator=","
                  :autoinit="true"
                />
              </h2>
              <p>
                <span class="passive-view"
                  ><i class="feather-arrow-up-right me-1"></i>30%</span
                >
                vs last month
              </p>
            </div>
          </div>
        </div-->
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 col-xl-9">
          <div class="card">
            <div class="card-body">
              <div class="chart-title patient-visit">
                <h4>Pacientes por genero</h4>
                <div>
                  <ul class="nav chat-user-total">
                    <li v-for="(percentage, gender) in objPorcentajeGenero" :key="gender">
                      <i class="fa fa-circle" :style="{ color: getColorGenero(gender) }" aria-hidden="true"></i>
                      {{ gender }} {{ percentage }}%
                    </li>
                  </ul>
                </div>
                <!--div class="input-block mb-0">
                  <vue-select :options="Years" placeholder="2022" />
                </div-->
              </div>

              <apexchart
                type="bar"
                height="250"
                :options="patientChart.chart"
                :series="patientChart.series">
              </apexchart>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-6 col-xl-3 d-flex">
          <div class="card">
            <div class="card-body">
              <div class="chart-title">
                <h4>Pacientes por tratamiento</h4>
              </div>
              <div id="donut-chart-dash" class="chart-user-icon">
                <apexchart
                  type="donut"
                  :options="donutChart.chartOptions"
                  :series="donutChart.series"
                  width="100%"
                  height="280"
                  >
                </apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!--div class="col-12 col-md-12 col-xl-4">
          <div class="card top-departments">
            <div class="card-header">
              <h4 class="card-title mb-0">Top Departments</h4>
            </div>
            <div class="card-body">
              <div class="activity-top">
                <div class="activity-boxs comman-flex-center">
                  <img src="@/assets/img/icons/dep-icon-01.svg" alt="" />
                </div>
                <div class="departments-list">
                  <h4>General Physician</h4>
                  <p>35%</p>
                </div>
              </div>
              <div class="activity-top">
                <div class="activity-boxs comman-flex-center">
                  <img src="@/assets/img/icons/dep-icon-02.svg" alt="" />
                </div>
                <div class="departments-list">
                  <h4>Dentist</h4>
                  <p>24%</p>
                </div>
              </div>
              <div class="activity-top">
                <div class="activity-boxs comman-flex-center">
                  <img src="@/assets/img/icons/dep-icon-03.svg" alt="" />
                </div>
                <div class="departments-list">
                  <h4>ENT</h4>
                  <p>10%</p>
                </div>
              </div>
              <div class="activity-top">
                <div class="activity-boxs comman-flex-center">
                  <img src="@/assets/img/icons/dep-icon-04.svg" alt="" />
                </div>
                <div class="departments-list">
                  <h4>Cardiologist</h4>
                  <p>15%</p>
                </div>
              </div>
              <div class="activity-top mb-0">
                <div class="activity-boxs comman-flex-center">
                  <img src="@/assets/img/icons/dep-icon-05.svg" alt="" />
                </div>
                <div class="departments-list">
                  <h4>Opthomology</h4>
                  <p>20%</p>
                </div>
              </div>
            </div>
          </div>
        </div-->
        <div class="col-12 col-md-12 col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title d-inline-block">Proximas citas</h4>
              <router-link
                :to="{ name: 'appointment-list' }"
                class="patient-views float-end"
                >Ver todas</router-link>
            </div>
            <div class="card-body p-0 table-dash">
              <div class="table-responsive">
                <AppointmentUpComingList />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--div class="row">
        <div class="col-12 col-xl-12">
          <div class="card">
            <div class="card-header pb-0">
              <h4 class="card-title d-inline-block">Recent Patients</h4>
              <router-link to="/patients/view" class="float-end patient-views"
                >Show all</router-link
              >
            </div>
            <div class="card-block table-dash">
              <div class="table-responsive">
                <recent-patients></recent-patients>
              </div>
            </div>
          </div>
        </div>
      </div-->
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import { PatientByGenderChart } from "./PatientByGenderChart";
import { PatientByTreatmentChart } from "./PatientByTreatmentChart";
import AppointmentUpComingList from "@/views/pages/appointment/AppointmentUpComingList.vue";

export default {
  data() {
    return {
      title: "Dashboard",
      text: "Admin Dashboard",
      Years: ["2022", "2021", "2020", "2019"],
      patientChart: PatientByGenderChart,
      donutChart: PatientByTreatmentChart,

      iDurationCounter: 2,
      iTotalPacientes: 0,
      iTotalCitas: 0,
      objDataUser: {},
      objDataMasculino: [],
      objDataFemenino: [],
      objDataPacienteGenero: [],
      objPorcentajeGenero: {},
      objDataPacienteTratamiento: {},
    };
  },
  components: {
    AppointmentUpComingList,
  },
  async created() {
    this.getDataUser();
    await this.getData();
    this.getPorcentajeGeneros();
    this.setDataDashboardPatientChart();
    this.setDataDashboardDonutChart();
  },
  methods: {
    getDataUser() {
      const token = localStorage.getItem('objUser');
      this.objDataUser = JSON.parse(token);
    },
    async getData() {
      const objResponse = await axios.get('dashboards/admin');
      this.iTotalPacientes = objResponse.data.totalPacientes;
      this.iTotalCitas = objResponse.data.totalCitas;
      this.objDataPacienteGenero = objResponse.data.pacientesDashboard;
      this.objDataPacienteTratamiento = objResponse.data.tratamientosDashboard;
      
    },
    setDataDashboardPatientChart() {
      const genderData = {};
      // Inicializa los datos para cada género
      Object.keys(this.objDataPacienteGenero).forEach(gender => {
        genderData[gender] = Array(12).fill(0);
      });

      // Llena los datos para cada género
      Object.keys(this.objDataPacienteGenero).forEach(gender => {
        this.objDataPacienteGenero[gender].forEach(item => {
          const monthIndex = parseInt(item.mes, 10) - 1;
          genderData[gender][monthIndex] += item.total;
        });
      });

      // Transforma el objeto en un array con la estructura deseada
      const transformedData = Object.keys(genderData).map(gender => {
        return {
          name: gender,
          color: this.getColorGenero(gender),
          data: genderData[gender],
        };
      });

      this.patientChart.series = transformedData;
    },
    setDataDashboardDonutChart() {
      const transformedData = Object.keys(this.objDataPacienteTratamiento).map(treatment => {
        return this.objDataPacienteTratamiento[treatment];
      });
      const series = transformedData.map(item => item.total);
      const labels = transformedData.map(item => item.tratamiento);

      this.donutChart.series = series;
      this.donutChart.chartOptions.labels = labels;
      
      
    },
    getColorGenero(gender) {
      const colors = {
        "Masculino": "#2E37A4",
        "Femenino": "#00D3C7",
        // Agrega más géneros y colores según sea necesario
      };
      return colors[gender] || "#000000"; // Color por defecto si no se encuentra el género
    },
    getPorcentajeGeneros() {
      const totalPatients = Object.values(this.objDataPacienteGenero).flat().reduce((sum, item) => sum + item.total, 0);
      const genderTotals = {};

      Object.keys(this.objDataPacienteGenero).forEach(gender => {
        genderTotals[gender] = this.objDataPacienteGenero[gender].reduce((sum, item) => sum + item.total, 0);
      });

      const genderPercentages = {};
      Object.keys(genderTotals).forEach(gender => {
        genderPercentages[gender] = Math.round((genderTotals[gender] / totalPatients) * 100);
      });

      this.objPorcentajeGenero = genderPercentages;
    },
      
  },
};
</script>
