<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-12">
                <div class="form-heading">
                  <h4>Detalle paciente</h4>
                </div>
              </div>

              <Spinner ref="Spinner" v-if="bSpinner"/>

              <div class="col-12 col-md-6 col-xl-4" v-for="(field, index) in lstFields" :key="index" v-if="bSpinner == false" >
                <div class="input-block local-forms">
                  <template v-if="field.type === 'range'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <div class="card-body roundskin-slider">
                      <Slider v-model="objForm[field.name]" class="roundskin" :min="0" :max="10" @change="handleRange" />
                    </div>
                  </template>
                  <template v-if="field.type === 'text' || field.type === 'date'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <input
                      class="form-control"
                      :type="field.type"
                      v-model="objForm[field.name]"
                      :placeholder="field.placeholder"
                      :required="field.required"
                      :disabled="field.disabled"
                    />
                  </template>
                  <template v-if="field.type === 'textarea'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <b-form-textarea
                      id="textarea"
                      v-model="objForm[field.name]"
                      rows="5"
                      max-rows="6"
                      placeholder="Ingresa el texto aqui"
                      :required="field.required">
                    </b-form-textarea>
                  </template>
                  <template v-if="field.type === 'select'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <vue-select
                      :options="field.options || []"
                      v-model="objForm[field.name]"
                      placeholder="Selecciona una opción"
                      @select="handleSelectField(field.name, $event)"
                      :disabled="field.disabled"
                      :required="field.required"
                    />
                  </template>
                  <template v-if="field.type === 'signature'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <template v-if="bFirma == false">
                      <Vue3Signature :ref="el => lstSignatureRefs[index] = el" :sigOption="objSignatureConfig.options" 
                      :w="'800px'" :h="'400px'" :disabled="objSignatureConfig.disabled" />
                      <b-button @click="handleClearSignature(index)" :disabled="objSignatureConfig.disabled" variant="secondary me-1">Limpiar firma</b-button>
                    </template>
                    <img :src="strImage" alt="Imagen en base64" v-if="bFirma" />
                  </template>
                </div>
              </div>
              <div class="col-12">
                <b-button type="submit" variant="primary me-1" v-if="bGuardar == false" >Guardar Registro</b-button>
                <b-button variant="primary me-1" v-if="bGuardar" >
                  <b-spinner class="spinner-border-sm me-1"></b-spinner>
                  Guardando...
                </b-button>
                <b-button @click="handleCancel" variant="secondary me-1">Cancelar</b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "@/axios";
import { format } from "date-fns";
import Slider from "@vueform/slider";
import Vue3Signature from "vue3-signature";
import Spinner from "@/components/Spinner.vue";

const lstSignatureRefs = ref([]);
const objSignatureConfig = {
  options: {
    canvasWidth: 800,
    canvasHeight: 400,
    backgroundColor: "rgb(249, 249, 249)",
    penColor: "rgb(0, 0, 0)",
    velocityFilterWeight: 0.7,
    minWidth: 0.5,
    maxWidth: 2.5,
    throttle: 16,
    minPointDistance: 3,
  },
  disabled: false,
};

const lstFields = [
  { name: "id_tratamiento", label: "Tratamiento", type: 'select', required: true, options: []},
  { name: "escala_dolor", label: "Escala de dolor", type: 'range', required: false},
  { name: "id_tipo_consulta", label: "Tipo de consulta", type: 'select', required: true, options: []},
  { name: "fecha_tratamiento", label: "Fecha de tratamiento", type: 'date', required: true},
  { name: "comentarios", label: "Comentarios", type: 'textarea', required: true},
  { name: "", label: "", type: ''},
  { name: "firma", label: "Firma", type: 'signature'},
];
export default {
  components: {
    Slider,
    Vue3Signature,
    Spinner,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    parentId: {
      type: String,
      default: null,
    },
    recordId: {
      type: String,
      default: null,
    },
  },
  
  data() {
    return {
      bSpinner: false,
      bGuardar: false,
      bFirma: false,
      strPath: "/patients/view",
      strImage: "",
      objSignatureConfig,
      objForm: this.handleInitForm(),
      lstSignatureRefs,
      lstFields,
    };
  },
  emits: ['submit', 'cancel', 'select'],
  async created() {
    if(!this.isEdit) this.objForm = this.handleInitForm();

    await this.getCatalogos();
    if(this.isEdit) await this.getConsultation();
    this.strPath = `/patient/${this.id}/medical-history/${this.parentId}/consultation/list`;
  },
  methods: {
    handleInitForm(){
      return {
        id_tratamiento: "",
        escala_dolor: "",
        id_tipo_consulta: "",
        fecha_tratamiento: format(new Date(), 'yyyy-MM-dd'),
        comentarios: "",
        firma: "",
      };
    },
    async getConsultation(){
      this.bSpinner = true;
      try {
        let strFirma = '';
        const objResponse = await axios.get(`/consultas/${this.recordId}`);
        this.objForm = objResponse.data;
        const dtFechaTratamiento = new Date(objResponse.data.fecha_tratamiento).toISOString().split('T')[0];
        this.objForm.id_tratamiento = String(objResponse.data.id_tratamiento);
        this.objForm.id_tipo_consulta = String(objResponse.data.id_tipo_consulta);
        this.objForm.fecha_tratamiento = objResponse.data.fecha_tratamiento ? dtFechaTratamiento : null;
        this.objForm.escala_dolor = Number(objResponse.data.escala_dolor);
        
        strFirma = this.objForm.firma;
        if(strFirma != null && strFirma.length > 5){
          this.bFirma = true;
          this.strImage = strFirma;
          /*
          this.objSignatureConfig.disabled = true;
          this.lstSignatureRefs.forEach((objRef) => {
            if (objRef && this.objForm.firma) {
              objRef.fromDataURL(this.objForm.firma);
            }
          });
          */
        }
      } catch (error) {
        console.error(`Error al obtener consulta: `, error);
      }
      this.bSpinner = false;
    },
    async getCatalogos() {
      this.bSpinner = true;
      try {
        const objResponse = await axios.get('/catalogos/consultas');
        this.updateFieldOptions('id_tratamiento', 'nombre', objResponse.data.tratamientos);
        this.updateFieldOptions('id_tipo_consulta', 'nombre', objResponse.data.tiposConsultas);
      } catch (error) {
        console.error('Error al obtener datos relacionados:', error);
      }
      this.bSpinner = false;
    },
    updateFieldOptions(strFieldIndex, strFieldName, objData) {
      const field = this.lstFields.find((field) => field.name === strFieldIndex);
      if (field && Array.isArray(objData)) {
        field.options = objData.map((item) => ({ id: String(item[strFieldIndex]), text: String(item[strFieldName]) }));
      }
      if (field && !Array.isArray(objData)) {
        field.options = [{ id: String(objData[strFieldIndex]), text: String(objData[strFieldName] )}];
      }
    },
    handleClearSignature(index) {
      this.lstSignatureRefs[index].clear();
    },
    handleRange(value) {
      this.objForm.escala_dolor = value;
    },
    handleSelectField(strFieldName, strValue) {
      if (typeof strValue !== "object" || Object.keys(strValue).length === 0) return;
      if (typeof strValue.id === "number") {
        strValue = String(strValue.id);
      } else if (typeof strValue.text === "number") {
        strValue = String(strValue.text);
      } else {
        strValue = strValue.id || strValue.text || "";
      }
      
      this.objForm[strFieldName] = strValue;
      this.$emit('select', strFieldName, strValue);
    },
    async submitForm() {
      if(!this.validateForm()) return;
      this.bGuardar = true;      
      this.objForm.id_paciente = this.id;
      this.objForm.id_historia_clinica = this.parentId;
      this.objForm.firma = this.lstSignatureRefs.filter(objRef => objRef !== null && !objRef.isEmpty())
        .map(objRef => objRef.save('image/png', 1, 0.5))[0];
      
      try{
        if(this.isEdit) await axios.put(`/consultas/${this.recordId}`, this.objForm);
        if(!this.isEdit) await axios.post("/consultas", this.objForm);
        this.toast('success', true, 'Consulta registrada correctamente');
        this.$router.go(-1)
      }catch(error){
        console.error('Error al registrar consulta:', error);
        this.toast('error', true, 'Ocurrio un error al registrar la consulta, intente nuevamente.');
      }
      this.bGuardar = false;
    },
    validateForm() {
      const lstRequiredFields = this.lstFields.filter((field) => field.required);
      const lstInvalidFields = lstRequiredFields.filter((field) => !this.objForm[field.name]);
      if (lstInvalidFields.length > 0) {
        this.toast('error', true, 'Favor de llenar todos los campos requeridos');
        return false;
      }
      return true;
    },
    handleCancel() {
      this.$router.go(-1)
    },
    toast(type = "default", dismissible = true, message = "") {
      const options = {
        dismissible,
        onClick: this.onClick,
        duration: 3000,
      };
      typeof type === "string" && (options.type = type);
      typeof options.maxToasts === "string" &&
        (options.maxToasts = parseInt(options.maxToasts));

      this.$toast.show(message, {
        ...options,
      });
    },
  },
};
</script>

<style>
.roundskin .slider-connect {
  background: var(--slider-connect-bg, #2e37a4) !important;
  cursor: pointer;
  height: 4px;
}
.roundskin .slider-base,
.slider-connects {
  height: 4px;
  position: relative;
  width: 100%;
  border-radius: 4px;
}
.roundskin .slider-tooltip {
  background: var(--slider-tooltip-bg, #006cfa) !important;
  border: 1px solid var(--slider-tooltip-bg, #006cfa) !important;
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
.roundskin-slider .slider-horizontal .slider-handle {
  top: calc(
    (var(--slider-handle-height, 30px) - var(--slider-height, 6px)) / 2 * -1 + 2px
  );
  width: 24px;
  height: 24px;
  position: absolute;
  display: block;
  border: 4px solid #006cfa;
  background-color: white;
  border-radius: 24px;
  box-shadow: none;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>