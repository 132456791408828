<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <PatologyList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import PatologyList from "./PatologyList.vue";

  export default {
    data() {
      return {
        strTitle: "Patologias",
        strSubutitle: "Lista de Patologias",
        strPath: "/settings/medical/patology",
      };
    },
    components: {
      PatologyList,
    },
    created() {
      
    },
    methods: {

    }
  };
</script>
  