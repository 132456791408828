<template>
  <div class="card">
    <div class="card-body bg-white">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-center">
            <!--p class="placeholder-glow">
              
              <span class="placeholder col-8"></span>
              <span class="placeholder col-8"></span>
              <span class="placeholder col-10"></span>
              <span class="placeholder col-12"></span>
              <span class="placeholder col-12"></span>
            </p-->
            <b-spinner
              type="grow"
              label="Cargando"
              class="avatar-lg text-secondary m-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  methods: {
  },
};
</script>