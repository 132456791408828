const PatientByTreatmentChart = {
  series: [],
  chartOptions: {
    chart: {
      type: "donut",
    },
    height: 290,
    labels: [],
    toolbar: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
      },
    },
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  },
  /*
  chart: {
    height: 290,
    type: "donut",
    toolbar: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "50%",
    },
  },
  series: [44, 55, 41, 17],
  labels: ["Electrodos","Punsion"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
  legend: {
    position: "bottom",
  },*/
};

export { PatientByTreatmentChart };
