<template>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="card bg-white">
            <div class="card-header">
              <h5 class="card-title">Configuración de la Organización</h5>
            </div>
            <div class="card-body">
              <b-tabs content-class="">
                <b-tab title="Empresa" active>
                  <CompanyList />
                </b-tab>
                <b-tab title="Sucursal">
                  <span>
                    <BranchList />
                  </span>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import BranchList from '../branch/BranchList.vue';
import CompanyList from '../company/CompanyList.vue';

export default {
  data() {
    return {};
  },
  components: {
    CompanyList,
    BranchList,
  },
};
</script>
