<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card">
              <div class="card-body">
                <ConsultationList :id="id" :parentId="parentId" />
              </div>
            </div>
          </div>

          <!--div class="col-12 col-md-12 col-lg-12 col-xl-4">
            <div class="card">
              <div class="card-body">
                <div class="chart-title patient-visit mb-0">
                  <h4>Progreso del paciente</h4>
                  <div class="average-health">
                    <h5>{{ strPromedio }} <span>promedio</span></h5>
                  </div>
                </div>
                <div id="health-chart">
                  <apexchart
                    type="line"
                    height="400"
                    :options="healthChart.chart"
                    :series="healthChart.series"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div-->
        </div>
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import axios from "@/axios";
  import ConsultationList from "./ConsultationList.vue";
  import { ProgressPatientChart } from "./ProgressChart.js";

  export default {
    props: {
      id: {
        type: String,
        default: null,
      },
      parentId: {
        type: String,
        default: "",
      },
    },
    
    data() {
      return {
        healthChart: ProgressPatientChart,
        strPromedio: 0,
        strTitle: "Paciente",
        strSubutitle: "Historia Clínica #",
        strPath: "/patients/view",
        objPaciente: {},
      };
    },
    components: {
      ConsultationList,
    },
    async created() {
      await this.getPaciente();
      await this.getDataDashboard();

      this.strSubutitle = `Historia Clínica #${this.parentId}`;
      this.strTitle = this.objPaciente.strNombreCompleto;
      this.strPath = `/patient/${this.id}/profile`;
    },
    methods: {
      async getPaciente() {
        try {
          const objResponse = await axios.get(`/pacientes/${this.id}`);
          this.objPaciente = objResponse.data;
          this.objPaciente.strNombreCompleto = `${this.objPaciente.nombre} ${this.objPaciente.apellido_paterno} ${this.objPaciente.apellido_materno}`;
        } catch (error) {
          console.error('Error fetching data:', error.message);
        }
      },
      async getDataDashboard() {
        try {
          const objResponse = await axios.get(`/historias-clinicas/${this.parentId}/consultas/dashboard/progreso`);
          this.strPromedio = objResponse.data.promedioEscalaDolor;
          const objSeries = {
            name: "Dolor",
            color: "#00D3C7",
            data: objResponse.data.escalasDolor,
          };
          
          this.healthChart.chart.xaxis.categories = objResponse.data.fechas;
          this.healthChart.series = [objSeries];

        } catch (error) {
          console.error('Error fetching data:', error.message);
        }
      },
    }
  };
</script>
  