const PatientByGenderChart = {
  chart: {
    height: 230,
    type: "bar",
    stacked: true,
    toolbar: { show: false, },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
    },
    yaxis: {
      title: {
        text: 'Pacientes'
      },
      labels: {
        formatter: function (val) {
          return Math.round(val);
        }
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Math.round(val);
        }
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "25%",
      },
    },
  },
  series: [
    /*
    Se establecen los valores en el siguiente formato:
    {
      name: "Male",
      color: "#2E37A4",
      data: [20, 30, 41, 67, 22, 43, 40, 10, 30, 20, 40],
    },
    */
  ],
};

export { PatientByGenderChart };
