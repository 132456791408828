<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <ProfessionList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import ProfessionList from "./ProfessionList.vue";

  export default {
    data() {
      return {
        strTitle: "Ocupaciones",
        strSubutitle: "Lista de Ocupaciones",
        strPath: "/settings/general/profession",
      };
    },
    components: {
      ProfessionList,
    },
    created() {
      
    },
    methods: {

    }
  };
</script>
  