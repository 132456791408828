<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <AllergyList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import AllergyList from "./AllergyList.vue";

  export default {
    data() {
      return {
        strTitle: "Alergias",
        strSubutitle: "Lista de Alergias",
        strPath: "/settings/medical/allergy",
      };
    },
    components: {
      AllergyList,
    },
    created() {
      
    },
    methods: {

    }
  };
</script>
  