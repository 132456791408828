<template>
  <div class="row">
    <div class="col-lg-4">
      <PatientProfileAdditionalInfo :id="id" />
    </div>
    
    <div class="col-lg-8">
      <PatientProfileMedicalInfo :id="id" />
    </div>
  </div>
</template>

<script>
  import PatientProfileAdditionalInfo from "./PatientProfileAdditionalInfo.vue";
  import PatientProfileMedicalInfo from "./PatientProfileMedicalInfo.vue";

  export default {
    props: {
      id: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        objData: {},
      };
    },
    components: {
      PatientProfileAdditionalInfo,
      PatientProfileMedicalInfo
    },
    created(){
      
    },
    methods: {
      async loadData() {
        try {
          
          
        } catch (error) {
          console.error(`Error al obtener historial medico: `, error);
        }
      },
    },
    
  };
</script>