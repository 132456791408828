<template>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="card bg-white">
            <div class="card-header">
              <h5 class="card-title">Configuración Agenda</h5>
            </div>
            <div class="card-body">
              <b-tabs content-class="">
                <b-tab title="Estatus Agenda" active>
                  <ScheduleStatusList />
                </b-tab>
                <b-tab title="Categoria Agenda">
                  <span>
                    <ScheduleCategoryList />
                  </span>
                </b-tab>
                
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ScheduleCategoryList from '../schedule-category/ScheduleCategoryList.vue';
import ScheduleStatusList from '../schedule-status/ScheduleStatusList.vue';


export default {
  data() {
    return {};
  },
  components: {
    ScheduleCategoryList,
    ScheduleStatusList,
  },
};
</script>
