<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
      <NeighborhoodForm :isEdit="isEdit" :id="id"/>
    </div>
  </div>
<delete></delete>
</template>
<script>
import NeighborhoodForm from "./NeighborhoodForm.vue";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      strTitle: "Ciudad",
      strSubutitle: "Formulario de Ciudad",
      strPath: "/settings/geographic/city",
      objPaciente: {},
    };
  },
  components: {
    NeighborhoodForm,
  },
  async created() {

  },
  methods: {

  }
};
</script>
