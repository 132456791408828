
<template>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-sm-8 col-4">
          <ul class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="list">Citas</router-link>
            </li>
            <li class="breadcrumb-item"><i class="feather-chevron-right"></i></li>
            <li class="breadcrumb-item active">Calendario</li>
          </ul>
        </div>
        <div class="col-sm-4 col-4 text-end m-b-30">
            <router-link
              :to="{ name: 'add-appointment' }"
              class="btn btn-primary btn-rounded">
              Crear cita
            </router-link>
            <b-button @click="getData" class="btn btn-rounded" variant="secondary me-1">Actualizar</b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card-box mb-0">
            <div class="row">
              <div class="col-md-12">
                <div id="calendar">
                  <FullCalendar
                    ref="fullCalendar"
                    :options="calendarOptions"
                    id="calendar-book">
                  </FullCalendar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="bModalEdit" id="delete_patient" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <img src="@/assets/img/sent.png" alt="" width="50" height="46" />
          <h3>¿Estás seguro que deseas editar el registro?</h3>
          <div class="m-t-20">
            <a href="javascript:;" class="btn btn-white" @click="handleConfirmModal(false)">Cancelar</a>
            <button type="button" class="btn btn-warning ms-1" @click="handleConfirmModal(true)">Editar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";
import dayjs from "dayjs";
import { notification } from "ant-design-vue";

import objCalendarOptions from "./CalendarOptions.js";
import FullCalendar from "@fullcalendar/vue3";
import { addHours, addDays, subDays } from "date-fns";

export default {
  components: {
    FullCalendar,
  },

  data() {
    return {
      bModalEdit: false,
      dtFechaInicioParam: subDays(new Date(), 7),
      dtFechaFinParam: addDays(new Date(), 7),
      calendarOptions: objCalendarOptions,
      objNuevaCita: {},
      objCitaSeleccionada: {},
      lstCitas: [],
      lstCitasCalendario: [],
    };
  },
  mounted() {
    objCalendarOptions.eventDrop = this.handleDropEvent;
    objCalendarOptions.datesSet = this.handleDatesSet;
    objCalendarOptions.eventClick = this.handleEventClick
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const objResponse = await axios.get(`/agendas?dtFechaInicio=${this.dtFechaInicioParam}&dtFechaFin=${this.dtFechaFinParam}`);
        this.lstCitas = objResponse.data;
        this.setData(this.lstCitas);
      } catch (error) {
        console.error('Error al obtener las citas:', error);
      }
    },
    setData(lstCitas) {
      lstCitas.forEach((objCita) => {
          if(this.lstCitasCalendario.some((objCitaCalendario) => Number(objCitaCalendario.id) === Number(objCita.id_agenda))) return;

          if(objCita.hora_inicio){
            const strPaciente = `${objCita.paciente.nombre} ${objCita.paciente.apellido_paterno} ${objCita.paciente.apellido_materno}`;
            const strColor = objCita.categoria_agenda?.color ?? '';

            const dtFecha = objCita.fecha_inicio.split('T')[0];
            const dtFechaFin = objCita.fecha_fin ? objCita.fecha_fin.split('T')[0] : dtFecha;
            const tHora = objCita.hora_inicio.split('T')[1];
            const tHoraFin = objCita.hora_fin ? objCita.hora_fin.split('T')[1] : addHours(new Date(`${dtFecha}T${tHora}`), 1).toISOString().split('T')[1];
          
            const dtFechaCita = dayjs(`${dtFecha}T${tHora}`).toISOString();
            const dtFechaFinCita = dayjs(`${dtFechaFin}T${tHoraFin}`).toISOString();
            
            this.lstCitasCalendario.push({
              id: objCita.id_agenda,
              title: `${strPaciente}`,
              start: dtFechaCita,
              end: dtFechaFinCita,
              allDay: false,
              backgroundColor: strColor,
            });
          }
          
        });
        objCalendarOptions.events = this.lstCitasCalendario;
    },
    async handleDropEvent(info) {
      this.objNuevaCita = this.lstCitas.find((objCita) => Number(objCita.id_agenda) === Number(info.event.id));
      if (!this.objNuevaCita) return;
      this.objNuevaCita.fecha_inicio = dayjs(info.event.start);
      this.objNuevaCita.hora_inicio = info.event.start;
      this.objNuevaCita.fecha_fin = dayjs(info.event.end);
      this.objNuevaCita.hora_fin = info.event.end;

      try{
        await axios.put(`/agendas/${this.objNuevaCita.id_agenda}`, this.objNuevaCita);
        this.openNotification('Cita creada correctamente');
        this.objNuevaCita = {};
      } catch (error) {
        console.error('Error al crear la cita:', error);
      }
    },
    handleEventClick(info) {
      this.objCitaSeleccionada = this.lstCitas.find((objCita) => Number(objCita.id_agenda) === Number(info.event.id));
      this.bModalEdit = true;
    }, 
    handleConfirmModal(bConfirm) {
      this.bModalEdit = bConfirm;
      if(bConfirm) this.$router.push({ name: 'edit-appointment-patient', 
        params: {
          id: this.objCitaSeleccionada.id_paciente, 
          recordId: this.objCitaSeleccionada.id_agenda 
        }});
      if(!bConfirm) this.objNuevaCita = {};
    },
    handleDatesSet(info) {
      this.dtFechaInicioParam = info.start;
      this.dtFechaFinParam = info.end;

      const bExist = this.lstCitasCalendario.some((objCita) => {
        const dtFechaInicio = dayjs(objCita.start);
        return dtFechaInicio.isSame(info.start, 'day');
      });

      if(!bExist) this.getData();
    },
    openNotification(strMessage) {
      notification.open({
        message: strMessage,
        placement: "bottomRight",
        bottom: "50px",
        duration: 3,
        style: {
          width: "250px",
          marginLeft: `${335 - 600}px`,
          background: "#2ab57d",
        },
      });
    },
  },
};

/*
<vue-select
:options="country"
id="country"
:settings="{width: '100%', dropdownParent:'#add_event'}" 
placeholder="Select Country"
@filter="fuseSearch" />
*/

</script>
