<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
      <PatientTestForm :isEdit="isEdit" :id="id" :historyId="historyId" :appointmentId="appointmentId" :testId="testId" />
    </div>
  </div>
<delete></delete>
</template>
<script>
import axios from '@/axios';
import PatientTestForm from "./PatientTestForm.vue";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    historyId: {
      type: String,
      default: "",
    },
    appointmentId: {
      type: String,
      default: "",
    },
    testId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      strTitle: "Paciente",
      strSubutitle: "Consulta",
      strPath: "/patients/view",
      objPaciente: {},
    };
  },
  components: {
    PatientTestForm,
  },
  async created() {
    await this.getPaciente();
    this.strTitle = `Historia Clinica #${this.historyId} (${this.objPaciente.strNombreCompleto})`;
    this.strPath = `/patient/${this.id}/medical-history/${this.historyId}/consultation/list`;
  },
  methods: {
    async getPaciente() {
      try {
        const objResponse = await axios.get(`/pacientes/${this.id}`);
        this.objPaciente = objResponse.data;
        this.objPaciente.strNombreCompleto = `${this.objPaciente.nombre} ${this.objPaciente.apellido_paterno} ${this.objPaciente.apellido_materno}`;
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    },
  }
};
</script>
