<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <CountryList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import CountryList from "./CountryList.vue";

  export default {
    data() {
      return {
        strTitle: "Paises",
        strSubutitle: "Lista de Paises",
        strPath: "/settings/geographic/country",
      };
    },
    components: {
      CountryList,
    },
    created() {
      
    },
    methods: {

    }
  };
</script>
  