<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <TestList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import TestList from "./TestList.vue";

  export default {
    data() {
      return {
        strTitle: "Pruebas Fisicas",
        strSubutitle: "Lista de Pruebas Fisicas",
        strPath: "/settings/medical/test",
      };
    },
    components: {
      TestList,
    },
    created() {
      
    },
    methods: {

    }
  };
</script>
  