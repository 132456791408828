<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <ReasonVisitList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import ReasonVisitList from "./ReasonVisitList.vue";

  export default {
    data() {
      return {
        strTitle: "Motivos de Consulta",
        strSubutitle: "Lista de Motivos de Consulta",
        strPath: "/settings/general/reason-visit",
      };
    },
    components: {
      ReasonVisitList,
    },
    created() {
      
    },
    methods: {

    }
  };
</script>
  