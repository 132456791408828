<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
      <AllergyForm :isEdit="isEdit" :id="id"/>
    </div>
  </div>
<delete></delete>
</template>
<script>
import AllergyForm from "./AllergyForm.vue";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      strTitle: "Alergia",
      strSubutitle: "Formulario de Alergia",
      strPath: "/settings/medical/allergy",
    };
  },
  components: {
    AllergyForm,
  },
  async created() {

  },
  methods: {

  }
};
</script>
