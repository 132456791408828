<template>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="card bg-white">
            <div class="card-header">
              <h5 class="card-title">Configuración Geografico</h5>
            </div>
            <div class="card-body">
              <b-tabs content-class="">
                <b-tab title="Pais" active>
                  <CountryList />
                </b-tab>
                <b-tab title="Localidad">
                  <span>
                    <StateList />
                  </span>
                </b-tab>
                <b-tab title="Ciudad">
                  <span>
                    <CityList />
                  </span>
                </b-tab>
                <b-tab title="Colonia">
                  <span>
                    <NeighborhoodList />
                  </span>
                </b-tab>
                <b-tab title="Codigo Postal">
                  <span>
                    <PostalCodeList />
                  </span>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CityList from '../city/CityList.vue';
import CountryList from '../country/CountryList.vue';
import NeighborhoodList from '../neighborhood/NeighborhoodList.vue';
import PostalCodeList from '../postal-code/PostalCodeList.vue';
import StateList from '../state/StateList.vue';

export default {
  data() {
    return {};
  },
  components: {
    CityList,
    CountryList,
    NeighborhoodList,
    PostalCodeList,
    StateList,
  },
};
</script>
