<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <RelationshipList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import RelationshipList from "./RelationshipList.vue";

  export default {
    data() {
      return {
        strTitle: "Parentescos",
        strSubutitle: "Lista de Parentescos",
        strPath: "/settings/general/relationship",
      };
    },
    components: {
      RelationshipList,
    },
    created() {
      
    },
    methods: {

    }
  };
</script>
  