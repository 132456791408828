<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <WorkTypeList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import WorkTypeList from "./WorkTypeList.vue";

  export default {
    data() {
      return {
        strTitle: "Esquema Laboral",
        strSubutitle: "Lista de Esquema Laboral",
        strPath: "/settings/general/work-type",
      };
    },
    components: {
      WorkTypeList,
    },
    created() {
      
    },
    methods: {

    }
  };
</script>
  