<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strSubTitle" :path="strPath" />
      <MedicalHistoryForm :isEdit="isEdit" :id="id" :recordId="recordId" />
    </div>
  </div>
<delete></delete>
</template>
<script>
import axios from '@/axios';
import MedicalHistoryForm from "./MedicalHistoryForm.vue";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    recordId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      strTitle: "Paciente",
      strSubTitle: "Historia clinica",
      strPath: "/patients/view",
      objPaciente: {},
    };
  },
  components: {
    MedicalHistoryForm,
  },
  async created() {
    await this.getPaciente();
    this.strTitle = this.objPaciente.strNombreCompleto;
    this.strPath = `/patient/${this.id}/profile`;
  },
  methods: {
    async getPaciente() {
      try {
        const objResponse = await axios.get(`/pacientes/${this.id}`);
        this.objPaciente = objResponse.data;
        this.objPaciente.strNombreCompleto = `${this.objPaciente.nombre} ${this.objPaciente.apellido_paterno} ${this.objPaciente.apellido_materno}`;
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    },
  }
};
</script>
