<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <NeighborhoodList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import NeighborhoodList from "./NeighborhoodList.vue";

  export default {
    data() {
      return {
        strTitle: "Ciudades",
        strSubutitle: "Lista de Ciudades",
        strPath: "/settings/geographic/city",
      };
    },
    components: {
      NeighborhoodList,
    },
    created() {
      
    },
    methods: {

    }
  };
</script>
  