<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <PostalCodeList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import PostalCodeList from "./PostalCodeList.vue";

  export default {
    data() {
      return {
        strTitle: "Codigos Postales",
        strSubutitle: "Lista de Codigos Postales",
        strPath: "/settings/geographic/postal-code",
      };
    },
    components: {
      PostalCodeList,
    },
    created() {
      
    },
    methods: {

    }
  };
</script>
  