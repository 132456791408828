<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <AnatomyList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import AnatomyList from "./AnatomyList.vue";

  export default {
    data() {
      return {
        strTitle: "Anatomia",
        strSubutitle: "Lista de Anatomia",
        strPath: "/settings/medical/anatomy",
      };
    },
    components: {
      AnatomyList,
    },
    created() {
      
    },
    methods: {

    }
  };
</script>
  