<template>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
      <div class="row">
        <!-- Lightbox -->
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mb-0">Nueva Cita</h4>
            </div>
            <div class="card-body">
              <div class="wizard">
                <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                  <li class="nav-item flex-fill" role="presentation" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="Información del paciente">
                    <div class="nav-link rounded-circle mx-auto d-flex align-items-center justify-content-center"
                      :class="{ active: tabId === 1 }" href="#step1" @click="tabId = 1" 
                      id="step1-tab" data-bs-toggle="tab" role="tab" 
                      aria-controls="step1" aria-selected="tabId === 1">
                      <i class="far fa-user"></i>
                    </div>
                  </li>
                  <li class="nav-item flex-fill" role="presentation" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="Company Document">
                    <div class="nav-link rounded-circle mx-auto d-flex align-items-center justify-content-center"
                      :class="{ active: tabId === 2 }" href="#step2" @click="tabId = 2" 
                      id="step2-tab" data-bs-toggle="tab" role="tab" 
                      aria-controls="step2" aria-selected="tabId === 2">
                      <i class="fas fa-calendar"></i>
                    </div>
                  </li>
                </ul>
                
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade" :class="{ show: tabId === 1, active: tabId === 1 }" role="tabpanel" id="step1" aria-labelledby="step1-tab">
                    <template v-if="bForm == false">
                      <AppointmentPatientList @paciente="handlePaciente" @onFormPaciente="handleFormPaciente" />
                    </template>
                    <template v-if="bForm && strForm == 'paciente'">
                      <AppointmentPatientForm ref="appointmentPatientForm" @paciente="handlePaciente" @onFormPaciente="handleFormPaciente" />
                    </template>
                  </div>

                  <div class="tab-pane fade" :class="{ show: tabId === 2, active: tabId === 2 }" role="tabpanel" id="step2" aria-labelledby="step2-tab">
                    <AppointmentForm ref="appointmentForm" :id="objPaciente.id_paciente" />
                  </div>
                  <!--div class="d-flex">
                    <template v-if="bForm == false">
                      <b-button @click="handleCancel" variant="btn btn-danger previous me-2">Cancelar</b-button>
                    </template>
                    <b-button @click="handlePrevious" variant="btn btn-primary previous me-2" v-if="bForm">Anterior</b-button>
                    <template v-if="bForm">
                      <b-button @click="handleSave" variant="btn btn-primary next" v-if="bCargar == false">Siguiente</b-button>
                      <b-button variant="btn btn-primary next" v-if="bCargar" >
                        <b-spinner class="spinner-border-sm me-1"></b-spinner>
                        Cargando...
                      </b-button>
                    </template>
                  </div-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
<script>
import AppointmentPatientForm from './AppointmentPatientForm.vue';
import AppointmentPatientList from './AppointmentPatientList.vue';
import AppointmentForm from '@/views/pages/appointment/AppointmentForm.vue';

const lstFields = [
  { name: "nombre", label: "Nombre", type: 'text', required: false, class: "col-lg-6"},
  { name: "apellido_paterno", label: "Apellido paterno", type: 'text', required: false, class: "col-lg-6"},
  { name: "apellido_materno", label: "Apellido materno", type: 'text', required: false, class: "col-lg-6"},
  { name: "fecha_nacimiento", label: "Fecha de nacimiento", type: 'date', required: false, class: "col-lg-6"},
  { name: "telefono_celular", label: "Celular", type: "text", required: false,  class: "col-lg-6"},
  { name: "id_genero", label: "Genero", type: "select", options: [], required: false, class: "col-lg-6"},
  ];

export default {
  data() {
    return {
      strTitle: "Citas",
      strSubutitle: "Nueva cita",
      strPath: "/appointment/list",
      bCargar: false,
      bForm: false,
      strForm: '',
      tabId: 1,
      objPaciente: {},
      lstFields,
    };
  },
  components: {
    AppointmentPatientList,
    AppointmentForm,
    AppointmentPatientForm,
  },
  mounted() {},
  created() {
    
  },
  methods: {
    handlePaciente(objPaciente) {
      this.objPaciente = objPaciente;
      this.objPaciente.id_paciente = String(objPaciente.id_paciente);
      this.handleNext();
    },
    handleFormPaciente(bForm) {
      this.bForm = bForm;
      this.strForm = 'paciente';
    },
    async handleSave() {
      this.bCargar = true;
      if(this.strForm == 'paciente' && this.bForm) await this.$refs.appointmentPatientForm.submitForm();
      //if(this.strForm == 'cita') await this.$refs.appointmentForm.submitForm();
      this.bCargar = false;
    },
    handleCancel() {
      this.$router.go(-1);
    },
    handleNext() {
      this.tabId = this.tabId + 1;
    },
    handlePrevious() {
      this.bForm = false;
    },
  },
};
</script>
