<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-12">
                <div class="form-heading">
                  <h4>Detalle Patologia Paciente</h4>
                </div>
              </div>

              <Spinner ref="Spinner" v-if="bSpinner"/>

              <div class="col-12 col-md-6 col-xl-4" v-for="(field, index) in lstFields" :key="index" v-if="bSpinner == false">
                <div class="input-block local-forms">
                  <template v-if="field.type === 'text' || field.type === 'date'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <input
                    class="form-control"
                    :type="field.type"
                    v-model="objForm[field.name]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    :disabled="field.disabled"
                    @input="updateValue"
                    />
                  </template>
                  <template v-if="field.type === 'select'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <vue-select
                    :options="field.options || []"
                    v-model="objForm[field.name]"
                    placeholder="Selecciona una opción"
                    @select="handleSelectField(field.name, $event)"
                    :disabled="field.disabled"
                    />
                  </template>
                </div>
              </div>
              <div class="col-12">
                <b-button type="submit" variant="primary me-1" v-if="bGuardar == false" >Guardar Registro</b-button>
                <b-button variant="primary me-1" v-if="bGuardar" >
                  <b-spinner class="spinner-border-sm me-1"></b-spinner>
                  Guardando...
                </b-button>
                <b-button @click="handleCancel" variant="secondary me-1">Cancelar</b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";
import Spinner from "@/components/Spinner.vue";

const lstActivo = [
{ id: "1", text: "Activo" },
{ id: "0", text: "Inactivo" },
];

const lstFields = [
{ name: "id_patologia", label: "Patologia", type: 'select', options: [], required: true},
{ name: "descripcion", label: "Descripcion", type: 'text'},
{ name: "activo", label: "Activo", type: 'select', options: lstActivo},
];

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    recordId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bSpinner: false,
      bGuardar: false,
      objForm: this.handleInitForm(),
      lstFields,
    };
  },
  emits: ['submit', 'cancel', 'select'],
  components: {
    Spinner,
  },
  async created() {
    if(!this.isEdit) this.objForm = this.handleInitForm();

    await this.getData();
    if(this.isEdit) await this.handleDataForm();
  },
  methods: {
    handleInitForm(){
      return {
        id_patologia: "",
        descripcion: "",
        activo: "1",
      };
    },
    async getData(){
      this.bSpinner = true;
      try {
        const objResponse = await axios.get(`/patologias`);
        let objData = objResponse.data;
        this.lstFields.find(objField => objField.name === "id_patologia").options =
          objData.map(objItem => ({ id: objItem.id_patologia, text: objItem.nombre }));
      } catch (error) {
        console.error(`Error al obtener consulta: `, error);
      }
      this.bSpinner = false;
    },
    async handleDataForm() {
      this.bSpinner = true;
      try {
          const objResponse = await axios.get(`patologias-pacientes/${this.recordId}`);
          this.objForm = objResponse.data;
          this.objForm.id_patologia = String(objResponse.data.id_patologia);
          
      } catch (error) {
        console.error(`Error al obtener datos del formulario: `, error);
      }
      this.bSpinner = false;
    },
    handleSelectField(strFieldName, strValue) {
      if (typeof strValue !== "object" || Object.keys(strValue).length === 0) return;
      if (typeof strValue.id === "number") {
        strValue = String(strValue.id);
      } else if (typeof strValue.text === "number") {
        strValue = String(strValue.text);
      } else {
        strValue = strValue.id || strValue.text || "";
      }
      
      this.objForm[strFieldName] = strValue;
      this.$emit('select', strFieldName, strValue);
    },
    async submitForm() {
      
      if(!this.validateForm()) return;
      this.bGuardar = true;
      if(!this.isEdit) this.objForm.id_paciente = this.id;

      try{
        if(this.isEdit) await axios.put(`/patologias-pacientes/${this.recordId}`, this.objForm);
        if(!this.isEdit) await axios.post("/patologias-pacientes", this.objForm);
        this.toast('success', true, 'Patologia registrado correctamente');
        this.$router.go(-1);
      }catch(error){
        console.error('Error al registrar la patologia:', error);
        this.toast('error', true, 'Ocurrio un error al registrar la patologia, intente nuevamente.');
      }
      this.bGuardar = false;
    },
    validateForm() {
      const lstRequiredFields = this.lstFields.filter((field) => field.required);
      const lstInvalidFields = lstRequiredFields.filter((field) => !this.objForm[field.name]);
      if (lstInvalidFields.length > 0) {
        this.toast('error', true, 'Favor de llenar todos los campos requeridos');
        return false;
      }
      return true;
    },
    handleCancel() {
      this.$router.go(-1);
    },
    toast(type = "default", dismissible = true, message = "") {
      const options = {
        dismissible,
        onClick: this.onClick,
        duration: 3000,
      };
      typeof type === "string" && (options.type = type);
      typeof options.maxToasts === "string" &&
        (options.maxToasts = parseInt(options.maxToasts));

      this.$toast.show(message, {
        ...options,
      });
    },
  },
};
</script>