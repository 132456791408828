<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <AppointmentTypeList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import AppointmentTypeList from "./AppointmentTypeList.vue";

  export default {
    data() {
      return {
        strTitle: "Tipo de Consulta",
        strSubutitle: "Lista de Tipo de Consulta",
        strPath: "/settings/general/appointment-type",
      };
    },
    components: {
      AppointmentTypeList,
    },
    created() {
      
    },
    methods: {

    }
  };
</script>
  