<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <form>
            <div class="row">
              <div class="col-12">
                <div class="form-heading">
                  <h4>Detalle Extremidad</h4>
                </div>
              </div>

              <Spinner ref="Spinner" v-if="bSpinner"/>

              <div class="col-12 col-md-6 col-xl-4" v-for="(field, index) in lstFields" :key="index" v-if="bSpinner == false">
                <div class="input-block local-forms">
                  <template v-if="field.type === 'text' || field.type === 'date'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <input
                    class="form-control"
                    :type="field.type"
                    v-model="objForm[field.name]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    :disabled="field.disabled"
                    @input="updateValue"
                    />
                  </template>
                  <template v-if="field.type === 'select'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <vue-select
                    :options="field.options || []"
                    v-model="objForm[field.name]"
                    placeholder="Selecciona una opción"
                    @select="handleSelectField(field.name, $event)"
                    :disabled="field.disabled"
                    />
                  </template>
                </div>
              </div>
              <div class="col-12">
                <b-button @click="submitForm" variant="primary me-1" v-if="bGuardar == false" >Guardar Registro</b-button>
                <b-button variant="primary me-1" v-if="bGuardar" >
                  <b-spinner class="spinner-border-sm me-1"></b-spinner>
                  Guardando...
                </b-button>
                <b-button @click="handleCancel" variant="secondary me-1">Cancelar</b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";
import { notification } from "ant-design-vue";
import Spinner from "@/components/Spinner.vue";

const lstActivo = [
{ id: "1", text: "Activo" },
{ id: "0", text: "Inactivo" },
];

const lstFields = [
{ name: "nombre", label: "Nombre", type: 'text', required: true},
{ name: "codigo", label: "Codigo", type: 'text'},
{ name: "descripcion", label: "Descripcion", type: 'text'},
{ name: "activo", label: "Activo", type: 'select', options: lstActivo},
];

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Array, Number],
      required: true,
    },
  },
  data() {
    return {
      bSpinner: false,
      bGuardar: false,
      objForm: this.handleInitForm(),
      lstFields,
    };
  },
  emits: ['submit', 'cancel', 'select'],
  components: {
    Spinner,
  },
  async created() {
    if(!this.isEdit) this.objForm = this.handleInitForm();
    if(this.isEdit) await this.getData();
  },
  methods: {
    handleInitForm(){
      return {
        nombre: "",
        codigo: "",
        descripcion: "",
        activo: "1",
      };
    },
    async getData(){
      this.bSpinner = true;
      try {
        const objResponse = await axios.get(`/extremidades/${this.id}`);
        this.objForm = objResponse.data;
        
      } catch (error) {
        console.error(`Error al obtener consulta: `, error);
      }
      this.bSpinner = false;
    },
    handleSelectField(strFieldName, strValue) {
      if (typeof strValue !== "object" || Object.keys(strValue).length === 0) return;
      if (typeof strValue.id === "number") {
        strValue = String(strValue.id);
      } else if (typeof strValue.text === "number") {
        strValue = String(strValue.text);
      } else {
        strValue = strValue.id || strValue.text || "";
      }
      
      this.objForm[strFieldName] = strValue;
      this.$emit('select', strFieldName, strValue);
    },
    async submitForm() {
      this.bGuardar = true;
      const lstFields = this.lstFields.filter(objField => objField.required);
      for (const objField of lstFields) {
        if (!this.objForm[objField.name]) {
          alert(`El campo ${objField.label} es requerido.`);
          return;
        }
      }
      
      try{
        if(this.isEdit) await axios.put(`/extremidades/${this.id}`, this.objForm);
        if(!this.isEdit) await axios.post("/extremidades", this.objForm);
        this.openNotification('Extremidad registrado correctamente');
        this.$router.go(-1);
      }catch(error){
        console.error('Error al registrar consulta:', error);
      }
      this.bGuardar = false;
    },
    emitSelectEvent() {
      this.$emit('select', { /* datos del evento */ });    
    },
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    handleCancel() {
      this.$router.go(-1);
    },
    openNotification(strMessage) {
      notification.open({
        message: strMessage,
        placement: "bottomRight",
        bottom: "50px",
        duration: 3,
        style: {
          width: "250px",
          marginLeft: `${335 - 600}px`,
          background: "#2ab57d",
        },
      });
    },
  },
};
</script>