<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-12">
                <div class="form-heading">
                  <h4>Detalle paciente</h4>
                </div>
              </div>

              <Spinner ref="Spinner" v-if="bSpinner"/>

              <div class="col-12 col-md-6 col-xl-4" v-for="(field, index) in lstFields" :key="index" v-if="bSpinner == false">
                <div class="input-block local-forms">
                  <template v-if="field.type !== 'select'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <input
                      class="form-control"
                      :type="field.type"
                      v-model="objForm[field.name]"
                      :placeholder="field.placeholder"
                      :required="field.required"
                      :disabled="field.disabled"
                    />
                  </template>
                  <template v-if="field.type === 'select'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <vue-select
                      :options="field.options || []"
                      v-model="objForm[field.name]"
                      placeholder="Selecciona una opción"
                      @select="handleSelectField(field.name, $event)"
                      :disabled="field.disabled"
                    />
                  </template>
                </div>
              </div>
              <div class="col-12">
                <b-button type="submit" variant="primary me-1" v-if="bGuardar == false" >Guardar Registro</b-button>
                <b-button variant="primary me-1" v-if="bGuardar" >
                  <b-spinner class="spinner-border-sm me-1"></b-spinner>
                  Guardando...
                </b-button>
                <b-button @click="handleCancel" variant="secondary me-1">Cancelar</b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";
import Spinner from "@/components/Spinner.vue";

const lstFields = [
  { name: "nombre", label: "Nombre", type: "text", required: true },
  { name: "apellido_paterno", label: "Apellido Paterno", type: "text", required: true },
  { name: "apellido_materno", label: "Apellido Materno", type: "text", required: false },
  { name: "fecha_nacimiento", label: "Fecha de Nacimiento", type: "date", required: true },
  { name: "id_genero", label: "Género", type: "select", required: true, options: [] },
  { name: "id_estado_civil", label: "Estado Civil", type: "select", required: false, options: [] },
  { name: "id_ocupacion", label: "Ocupación", type: "select", required: false, options: [] },
  { name: "id_esquema_laboral", label: "Esquema Laboral", type: "select", required: false, options: [] },
  { name: "id_tipo_paciente", label: "Tipo de Paciente", type: "select", required: false, options: [] },
  { name: "id_estatus_paciente", label: "Estatus de Paciente", type: "select", required: false, options: [] },
  { name: "id_sucursal", label: "Sucursal", type: "select", required: false, options: [] },
  { name: "correo_electronico", label: "Correo Electrónico", type: "email", required: false },
  { name: "telefono_casa", label: "Teléfono de Casa", type: "text", required: false },
  { name: "telefono_celular", label: "Teléfono Celular", type: "text", required: true },
  { name: "telefono_trabajo", label: "Teléfono de Trabajo", type: "text", required: false },
  { name: "telefono_emergencia", label: "Teléfono de Emergencia", type: "text", required: false },
  { name: "nombre_emergencia", label: "Nombre de Emergencia", type: "text", required: false },
  { name: "id_parentesco", label: "Parentesco", type: "select", required: false },
  { name: "id_codigo_postal", label: "Código Postal", type: "select", required: false },
  { name: "id_pais", label: "País", type: "select", required: false, disabled: true, options: [] },
  { name: "id_localidad", label: "Localidad", type: "select", required: false, disabled: true, options: [] },
  { name: "id_ciudad", label: "Ciudad", type: "select", required: false, disabled: true, options: [] },
  { name: "id_colonia", label: "Colonia", type: "select", required: false, options: [] },
  { name: "calle", label: "Calle", type: "text", required: false },
  { name: "numero_interior", label: "Número Interior", type: "text", required: false },
  { name: "numero_exterior", label: "Número Exterior", type: "text", required: false },
];

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Array, Number],
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue", "select"],
  data() {
    const strTitle = "Pacientes";
    return {
      bSpinner: false,
      bGuardar: false,
      strTitle,
      strSubTitle: "Detalle de paciente",
      objForm: this.handleInitForm(),
      lstFields,
    };
  },
  components: {
    Spinner,
  },
  async created() {
    await this.getCatalogos();
    if (this.isEdit && this.id) {
      await this.getPaciente();
    }
    if(!this.isEdit) this.objForm = this.handleInitForm();
  },
  methods: {
    handleInitForm(){
      return {
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        fecha_nacimiento: "",
        id_genero: "",
        id_estado_civil: "",
        id_ocupacion: "",
        id_esquema_laboral: "",
        id_tipo_paciente: "",
        id_estatus_paciente: "",
        id_sucursal: "",
        correo_electronico: "",
        telefono_casa: "",
        telefono_celular: "",
        telefono_trabajo: "",
        telefono_emergencia: "",
        nombre_emergencia: "",
        id_parentesco: "",
        id_codigo_postal: "",
        id_pais: "",
        id_localidad: "",
        id_ciudad: "",
        id_colonia: "",
        calle: "",
        numero_interior: "",
        numero_exterior: "",
      };
    },
    async getPaciente() {
      this.bSpinner = true;
      try {
        const objResponse = await axios.get(`/pacientes/${this.id}`);
        this.objForm = objResponse.data;
        this.objForm.fecha_nacimiento = new Date(this.objForm.fecha_nacimiento).toISOString().split('T')[0];
        this.objForm.id_genero = String(objResponse.data.id_genero);
        this.objForm.id_estado_civil = String(objResponse.data.id_estado_civil);
        this.objForm.id_ocupacion = String(objResponse.data.id_ocupacion);
        this.objForm.id_esquema_laboral = String(objResponse.data.id_esquema_laboral);
        this.objForm.id_tipo_paciente = String(objResponse.data.id_tipo_paciente);
        this.objForm.id_estatus_paciente = String(objResponse.data.id_estatus_paciente);
        this.objForm.id_sucursal = String(objResponse.data.id_sucursal);
        this.objForm.id_parentesco = String(objResponse.data.id_parentesco);
        this.objForm.id_codigo_postal = String(objResponse.data.id_codigo_postal);
        this.objForm.id_pais = String(objResponse.data.id_pais);
        this.objForm.id_localidad = String(objResponse.data.id_localidad);
        this.objForm.id_ciudad = String(objResponse.data.id_ciudad);
        this.objForm.id_colonia = String(objResponse.data.id_colonia);

        this.lstFields.forEach((objField) => {
          if (objField.type === 'select' && objField.options) {
            const objOption = objField.options.find((option) => option.id === this.objForm[objField.name]);
            if (objOption) this.objForm[objField.name] = objOption.id;
          }
        });
        
        if (this.objForm.id_codigo_postal) {
          await this.getRelatedDataByPostalCode(this.objForm.id_codigo_postal);
        }
        this.$emit("update:modelValue", this.objForm);
      } catch (error) {
        console.error(`Error al obtener paciente: `, error);
      }
      this.bSpinner = false;
    },
    async getCatalogos() {
      try {
        const objResponse = await axios.get('/catalogos/pacientes');

        this.updateFieldOptions('id_genero', 'nombre', objResponse.data.generos);
        this.updateFieldOptions('id_estado_civil', 'nombre', objResponse.data.estadosCiviles);
        this.updateFieldOptions('id_ocupacion', 'nombre', objResponse.data.ocupaciones);
        this.updateFieldOptions('id_esquema_laboral', 'nombre', objResponse.data.esquemasLaborales);
        this.updateFieldOptions('id_tipo_paciente', 'nombre', objResponse.data.tiposPacientes);
        this.updateFieldOptions('id_estatus_paciente', 'nombre', objResponse.data.estatusPacientes);
        this.updateFieldOptions('id_sucursal', 'nombre', objResponse.data.sucursales);
        this.updateFieldOptions('id_parentesco', 'nombre', objResponse.data.parentescos);
        this.updateFieldOptions('id_codigo_postal', 'codigo_postal', objResponse.data.codigosPostales);

      } catch (error) {
        console.error('Error al obtener datos relacionados:', error);
      }
    },
    updateFieldOptions(strFieldIndex, strFieldName, objData) {
      let lstSelected = ['id_pais', 'id_localidad', 'id_ciudad'];
      const field = this.lstFields.find((field) => field.name === strFieldIndex);
      
      if (field && Array.isArray(objData))
        field.options = objData.map((item) => ({ id: String(item[strFieldIndex]), text: String(item[strFieldName]) }));

      if (field && !Array.isArray(objData))
        field.options = [{ id: String(objData[strFieldIndex]), text: String(objData[strFieldName] )}];

      if(lstSelected.includes(strFieldIndex))
        this.objForm[strFieldIndex] = field.options[0].id;
    },
    async getRelatedDataByPostalCode(strCodigoPostal) {
      try {
        const objCodigoPostal = await axios.get(`/codigos-postales/${strCodigoPostal}`);
        const [objPaisResponse, objLocalidadResponse, objCiudadResponse, objColoniasResponse] = await Promise.all([
          axios.get(`/paises/${objCodigoPostal.data.id_pais}`),
          axios.get(`/localidades/${objCodigoPostal.data.id_localidad}`),
          axios.get(`/ciudades/${objCodigoPostal.data.id_ciudad}`),
          axios.get(`/codigos-postales/${strCodigoPostal}/colonias`),
        ]);

        this.updateFieldOptions('id_pais', 'nombre', objPaisResponse.data);
        this.updateFieldOptions('id_localidad', 'nombre', objLocalidadResponse.data);
        this.updateFieldOptions('id_ciudad', 'nombre', objCiudadResponse.data);
        this.updateFieldOptions('id_colonia', 'nombre', objColoniasResponse.data);
      } catch (error) {
        console.error('Error al obtener datos por código postal:', error);
      }
    },
    handleSelectField(strFieldName, strValue) {
      if (typeof strValue !== "object" || Object.keys(strValue).length === 0) return;
      if (typeof strValue.id === "number") {
        strValue = String(strValue.id);
      } else if (typeof strValue.text === "number") {
        strValue = String(strValue.text);
      } else {
        strValue = strValue.id || strValue.text || "";
      }

      this.objForm[strFieldName] = strValue;
      this.$emit("update:modelValue", strValue);

      if (strFieldName === "id_codigo_postal") this.getRelatedDataByPostalCode(strValue);
    },
    async submitForm() {
      if(!this.validateForm()) return;
      this.bGuardar = true;
      try {
        if (this.isEdit) await axios.put(`/pacientes/${this.id}`, this.objForm);
        if (!this.isEdit) await axios.post("/pacientes", this.objForm);
        this.toast('success', true, 'Paciente registrado correctamente');
        this.$router.go(-1);
      } catch (error) {
        console.log('Error al registrar paciente:', error);
        this.toast('error', true, 'Error al registrar paciente, intente de nuevo.');
      }
      this.bGuardar = false;
    },
    validateForm() {
      const lstRequiredFields = this.lstFields.filter((field) => field.required);
      const lstInvalidFields = lstRequiredFields.filter((field) => !this.objForm[field.name]);
      if (lstInvalidFields.length > 0) {
        this.toast('error', true, 'Favor de llenar todos los campos requeridos');
        return false;
      }
      return true;
    },
    handleCancel() {
      this.$router.go(-1);
    },
    toast(type = "default", dismissible = true, message = "") {
      const options = {
        dismissible,
        onClick: this.onClick,
        duration: 3000,
      };
      typeof type === "string" && (options.type = type);
      typeof options.maxToasts === "string" &&
        (options.maxToasts = parseInt(options.maxToasts));

      this.$toast.show(message, {
        ...options,
      });
    },
  },
};
</script>
