<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <AppointmentList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import AppointmentList from "./AppointmentList.vue";

  export default {
    data() {
      return {
        strTitle: "Citas",
        strSubutitle: "Lista de citas",
        strPath: "/appointment/list",
        objPaciente: {},
      };
    },
    components: {
      AppointmentList,
    },
    async created() {
  
    },
    methods: {

    }
  };
</script>
  