<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
      <AllergyPatientForm :isEdit="isEdit" :id="id" :recordId="recordId"/>
    </div>
  </div>
<delete></delete>
</template>
<script>
import AllergyPatientForm from "./AllergyPatientForm.vue";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    recordId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      strTitle: `Paciente #${this.id}`,
      strSubutitle: "Formulario de Alergias",
      strPath: `/patient/${this.id}/profile`,
    };
  },
  components: {
    AllergyPatientForm,
  },
  async created() {

  },
  methods: {

  }
};
</script>
