<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <BranchList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import BranchList from "./BranchList.vue";

  export default {
    data() {
      return {
        strTitle: "Sucursal",
        strSubutitle: "Lista de Sucursal",
        strPath: "/settings/business/branch",
      };
    },
    components: {
      BranchList,
    },
    created() {
      
    },
    methods: {

    }
  };
</script>
  