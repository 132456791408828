<template>
    <div class="page-wrapper">
      <div class="content">
        <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
  
        <MaritalStatusList />
      </div>
    </div>
  <delete></delete>
</template>
<script>
  import MaritalStatusList from "./MaritalStatusList.vue";

  export default {
    data() {
      return {
        strTitle: "Estado Civil",
        strSubutitle: "Lista de Estado Civil",
        strPath: "/settings/general/marial-status",
      };
    },
    components: {
      MaritalStatusList,
    },
    created() {
      
    },
    methods: {

    }
  };
</script>
  