<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
      <MaritalStatusForm :isEdit="isEdit" :id="id"/>
    </div>
  </div>
<delete></delete>
</template>
<script>
import MaritalStatusForm from "./MaritalStatusForm.vue";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      strTitle: "Estados Civiles",
      strSubutitle: "Formulario de Estados Civiles",
      strPath: "/settings/general/marial-status",
      objPaciente: {},
    };
  },
  components: {
    MaritalStatusForm,
  },
  async created() {

  },
  methods: {

  }
};
</script>
