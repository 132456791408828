<template>
  <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="row">
                <div class="col-12">
                  <div class="form-heading">
                    <h4>Detalle paciente</h4>
                  </div>
                </div>

                <Spinner ref="Spinner" v-if="bSpinner"/>

                <div class="col-12 col-md-6 col-xl-4" v-for="(field, index) in lstFields" :key="index" v-if="bSpinner == false" >
                  <div class="input-block local-forms">
                    <template v-if="field.type === 'text' || field.type === 'date'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <input
                        class="form-control"
                        :type="field.type"
                        v-model="objForm[field.name]"
                        :placeholder="field.placeholder"
                        :required="field.required"
                        :disabled="field.disabled"
                      />
                    </template>
                    <template v-if="field.type === 'select'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <vue-select
                        :options="field.options || []"
                        v-model="objForm[field.name]"
                        placeholder="Selecciona una opción"
                        @select="handleSelectField(field.name, $event)"
                        :disabled="field.disabled"
                        :required="field.required"
                      />
                    </template>
                  </div>
                </div>
                <div class="col-12">
                <b-button type="submit" variant="primary me-1" v-if="bGuardar == false" >Guardar Registro</b-button>
                <b-button variant="primary me-1" v-if="bGuardar" >
                  <b-spinner class="spinner-border-sm me-1"></b-spinner>
                  Guardando...
                </b-button>
                <b-button @click="handleCancel" variant="secondary me-1">Cancelar</b-button>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
  import axios from '@/axios';
  import Spinner from "@/components/Spinner.vue";

  const lstFields = [
    { name: "id_extremidad", label: "Extremidad", type: 'select', required: true, options: []},
    { name: "id_cuerpo_humano", label: "Parte Cuerpo", type: 'select', required: true, options: []},
    { name: "id_prueba_fisica", label: "Prueba ejecutada", type: 'select', required: true, options: []},
    { name: "descripcion", label: "Comentarios", type: 'text', required: true},
  ];
  export default {
    props: {
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: null,
      },
      historyId: {
        type: String,
        default: null,
      },
      appointmentId: {
        type: String,
        default: null,
      },
      testId: {
        type: String,
        default: null,
      },
    },
    components: {
      Spinner,
    },
    data() {
      return {
        bSpinner: false,
        bGuardar: false,
        strSubTitle: "Consulta",
        objPaciente: {},
        objForm: this.handleInitForm(),
        lstFields,
      };
    },
    async created() {
      if(!this.isEdit) this.objForm = this.handleInitForm();
      await this.getCatalogos();
      if(this.isEdit) await this.getPatientTest();
    },
    methods: {
      handleInitForm(){
        return {
          id_extremiedad: "",
          id_cuerpo_humano: "",
          id_prueba_fisica: "",
          descripcion: "",
        };
      },
      async getPatientTest(){
        this.bSpinner = true;
        try {
          const objResponse = await axios.get(`/pruebas-pacientes/${this.testId}`);
          this.objForm = objResponse.data;     
        } catch (error) {
          console.error(`Error al obtener consulta: `, error);
        }
        this.bSpinner = false;
      },
      async getCatalogos() {
        this.bSpinner = true;
        try {
            const objResponse = await axios.get('/catalogos/pruebas-pacientes');
            this.updateFieldOptions('id_extremidad', 'nombre', objResponse.data.extremidades);
            this.updateFieldOptions('id_cuerpo_humano', 'nombre', objResponse.data.cuerposHumanos);
            this.updateFieldOptions('id_prueba_fisica', 'nombre', objResponse.data.pruebasFisicas);
        } catch (error) {
            console.error('Error al obtener datos relacionados:', error);
        }
        this.bSpinner = false;
      },
      updateFieldOptions(strFieldIndex, strFieldName, objData) {
          const field = this.lstFields.find((field) => field.name === strFieldIndex);
          if (field && Array.isArray(objData)) {
              field.options = objData.map((item) => ({ id: String(item[strFieldIndex]), text: String(item[strFieldName]) }));
          }
          if (field && !Array.isArray(objData)) {
              field.options = [{ id: String(objData[strFieldIndex]), text: String(objData[strFieldName] )}];
          }
      },
      handleSelectField(strFieldName, strValue) {
        if (typeof strValue !== "object" || Object.keys(strValue).length === 0) return;
        if (typeof strValue.id === "number") {
          strValue = String(strValue.id);
        } else if (typeof strValue.text === "number") {
          strValue = String(strValue.text);
        } else {
          strValue = strValue.id || strValue.text || "";
        }

        this.objForm[strFieldName] = strValue;
      },
      async submitForm() {
        if(!this.validateForm()) return;
        this.bGuardar = true;
        this.objForm.id_paciente =  this.id;
        this.objForm.id_historia_clinica =  this.historyId;
        this.objForm.id_consulta = this.appointmentId;

        try{
          if(this.isEdit) await axios.put(`/pruebas-pacientes/${this.testId}`, this.objForm);
          if(!this.isEdit) await axios.post("/pruebas-pacientes", this.objForm);
          this.toast('success', true, 'Prueba registrada correctamente');
          this.$router.go(-1);
        }catch(error){
          console.error('Error al registrar prueba:', error);
          this.toast('error', true, 'Ocurrio un error al registrar la prueba, intente nuevamente.');
        }
        this.bGuardar = false;
      },
      validateForm() {
        const lstRequiredFields = this.lstFields.filter((field) => field.required);
        const lstInvalidFields = lstRequiredFields.filter((field) => !this.objForm[field.name]);
        if (lstInvalidFields.length > 0) {
          this.toast('error', true, 'Favor de llenar todos los campos requeridos');
          return false;
        }
        return true;
      },
      handleCancel() {
        this.$router.go(-1);
      },
      toast(type = "default", dismissible = true, message = "") {
        const options = {
          dismissible,
          onClick: this.onClick,
          duration: 3000,
        };
        typeof type === "string" && (options.type = type);
        typeof options.maxToasts === "string" &&
          (options.maxToasts = parseInt(options.maxToasts));

        this.$toast.show(message, {
          ...options,
        });
      },
    },
  };
</script>