<template>
    <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Categoria Agenda</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form @submit.prevent="handleSearch">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Buscar paciente"
                              v-model="strSearchQuery"
                            />
                            <a class="btn"
                              ><img src="@/assets/img/icons/search-normal.svg" alt=""
                            /></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <router-link
                            :to="{ name: 'add-schedule-category' }"
                            class="btn btn-primary add-pluss ms-2"
                            ><img src="@/assets/img/icons/plus.svg" alt=""
                          /></router-link>
                          <a
                            href="javascript:;"
                            class="btn btn-primary doctor-refresh ms-2"
                            @click="getData"
                            ><img src="@/assets/img/icons/re-fresh.svg" alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <Spinner ref="Spinner" v-if="bSpinner" />

              <!-- Table -->
              <div class="table-responsive" v-if="bSpinner == false">
                <a-table
                  class="table border-0 custom-table comman-table datatable mb-0"
                  :columns="lstColumns"
                  :dataSource="handleFilterData"
                  :rowSelection="handleRowSelection"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'id_categoria_agenda'">
                      <div class="profile-image">
                        SCHCAT-#{{ record.id_categoria_agenda }}
                      </div>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="javascript:;"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa fa-ellipsis-v"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <router-link class="dropdown-item" 
                              :to="{ name: 'edit-schedule-category', params: {id: record.id_categoria_agenda}}">
                              <i class="fa-solid fa-pen-to-square m-r-5"></i> Editar
                            </router-link>
                            <!--a class="dropdown-item"
                              href="javascript:;"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient">
                              <i class="fa fa-trash-alt m-r-5"></i> Eliminar
                            </a-->
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
              <!-- /Table -->
            </div>
          </div>
        </div>
    </div>
    <delete></delete>
</template>
<script>
  import axios from "@/axios";
  import { format } from 'date-fns';
  import Spinner from "@/components/Spinner.vue";

  function createColumns(lstColumns) {
    return lstColumns.map((objColumn) => ({
      title: objColumn.strTitle,
      dataIndex: objColumn.strDataIndex,
      key: objColumn.strKey || objColumn.strDataIndex,
      sorter: objColumn.bSorter !== false ? {
        compare: (a, b) => {
          const aValue = a[objColumn.strDataIndex];
          const bValue = b[objColumn.strDataIndex];

          // Verificar si los valores son cadenas y convertir a minúsculas
          const aValueStr = (typeof aValue === 'string' && aValue !== null ) ? aValue.toLowerCase() : aValue;
          const bValueStr = (typeof bValue === 'string' && bValue !== null ) ? bValue.toLowerCase() : bValue;

          // Comparar valores
          if (aValueStr < bValueStr) return -1;
          if (aValueStr > bValueStr) return 1;
          return 0;
        },
      } : undefined,
      class: objColumn.strClass || "",
    }));
  }

  const lstColumnsToCreate = [
    { strTitle: "Nombre", strDataIndex: "nombre", strKey: "nombre", bFilter: true },
    { strTitle: "Codigo", strDataIndex: "codigo", strKey: "codigo", bFilter: true },
    { strTitle: "Descripcion", strDataIndex: "descripcion", strKey: "descripcion", bFilter: true },
    { strTitle: "Activo", strDataIndex: "strActivo", strKey: "strActivo"},
    { strTitle: "Fecha Creación", strDataIndex: "strFechaCreacion", strKey: "strFechaCreacion"},
    { strTitle: "", strDataIndex: "action", strKey: "action", sorter: false, strClass: "text-end"},
  ];

  const lstRowSelected = [];
  const lstColumns = createColumns(lstColumnsToCreate);
  const lstData = [];
  export default {

    emits: ["update:modelValue", "select"],
    data() {
      return {
        bSpinner: false,
        strSearchQuery: "",
        lstData,
        lstColumns,
        lstRowSelected,
        lstColumnsToCreate,
      };
    },
    components: {
      Spinner,
    },
    computed: {
      handleFilterData() {
      if (!this.strSearchQuery) return this.lstData;
        const query = this.strSearchQuery.toLowerCase();

        return this.lstData.filter((objData) => {
          return this.lstColumnsToCreate.some((objColumn) => {
            if (!objColumn.bFilter) return false;
            const strValue = objData[objColumn.strDataIndex];
            const strValueSearch = (typeof strValue === 'string' && strValue !== null) ? strValue.toLowerCase() : '';
            return strValueSearch.includes(query);
          });
        });
      },
      handleRowSelection(){
        return {
          selectedRowKeys: this.lstRowSelected,
          onChange: this.handleRowSelectionChange,
        }
      }
    },
    async created() {
      await this.getData();
    },
    methods: {
      async getData() {
        this.bSpinner = true;
        try {
          const objResponse = await axios.get(`/categorias-agenda`);
          this.lstData = objResponse.data.map((objResponse) => ({
            ...objResponse,
            strActivo: objResponse.activo ? "Activo" : "Inactivo",
            strFechaCreacion: format(new Date(objResponse.fecha_creacion), 'dd/MM/yyyy HH:mm:ss'),
          }));
        } catch (error) {
          console.error('Error fetching data:', error.message);
        }
        this.bSpinner = false;
      },
      handleRowSelectionChange(selectedRowKeys) {
        this.lstRowSelected = selectedRowKeys;
      },
      handleSearch() {
        // Este método se llama automáticamente debido a la propiedad computada `filteredData`
      },
    }
  };
</script>
