const ProgressPatientChart = {
  chart: {
    height: 170,
    type: "line",
    toolbar: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [],
    },
  },  
  series: [
    {
      name: "Dolor",
      color: "#00D3C7",
      data: [],
    },
  ],
};

export { ProgressPatientChart };
