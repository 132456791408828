<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card card-table show-entire">
        <div class="card-body">
          <!-- Table Header -->
          <div class="page-table-header mb-2">
            <div class="row align-items-center">
              <div class="col">
                <div class="doctor-table-blk">
                  <h3>Lista de pacientes</h3>
                  <div class="doctor-search-blk">
                    <div class="top-nav-search table-search-blk">
                      <form @submit.prevent="handleSearch">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Buscar paciente"
                          v-model="strSearchQuery"
                        />
                        <a class="btn"
                          ><img src="@/assets/img/icons/search-normal.svg" alt=""
                        /></a>
                      </form>
                    </div>
                    <div class="add-group">
                      <router-link
                        :to="{ name: 'patient-new' }"
                        class="btn btn-primary add-pluss ms-2"
                        ><img src="@/assets/img/icons/plus.svg" alt=""
                      /></router-link>
                      <a
                        href="javascript:;"
                        class="btn btn-primary doctor-refresh ms-2"
                        @click="getPacientes"
                        ><img src="@/assets/img/icons/re-fresh.svg" alt=""
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Table Header -->

          <!-- Table -->
          <div class="table-responsive">
            <a-table
              class="table border-0 custom-table comman-table datatable mb-0"
              :columns="lstColumns"
              :dataSource="handleFilterData"
              :rowSelection="handleRowSelection"
              :rowKey="record => record.id_paciente"
              :pagination="{ pageSize: 15 }"
              :loading="bSpinner"
              >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'id_paciente'">
                  <div class="profile-image">
                    <router-link :to="{ name: 'patient-profile', params: { id: record.id_paciente } }">
                      <img
                        width="28"
                        height="28"
                        :src="require(`@/assets/img/user.jpg`)"
                        class="rounded-circle m-r-5"
                        alt=""/>
                      PX-#{{ record.id_paciente }}
                    </router-link>
                  </div>
                </template>
                <template v-if="column.key === 'nombre_completo'">
                  <div class="profile-image">
                    <router-link :to="{ name: 'patient-profile', params: { id: record.id_paciente } }">
                      {{ record.nombre_completo }}
                    </router-link>
                  </div>
                </template>
                <template v-else-if="column.key === 'telefono_celular'">
                  <div><a href="javascript:;">{{ record.telefono_celular }}</a></div>
                </template>
                <template v-else-if="column.key === 'action'">
                  <div class="text-end">
                    <div class="dropdown dropdown-action">
                      <a
                        href="javascript:;"
                        class="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-ellipsis-v"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link class="dropdown-item" 
                          :to="{ name: 'patient-edit', params: {id: record.id_paciente}}">
                          <i class="fa-solid fa-pen-to-square m-r-5"></i> Editar
                        </router-link>
                        <a
                          class="dropdown-item"
                          href="javascript:;"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_patient"
                        >
                          <i class="fa fa-trash-alt m-r-5"></i> Eliminar
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
          <!-- /Table -->
        </div>
      </div>
    </div>
  </div>
  <delete></delete>
</template>
<script>
  import axios from "@/axios";
  import { format } from 'date-fns';

  function createColumns(lstColumns) {
    return lstColumns.map((objColumn) => ({
      title: objColumn.strTitle,
      dataIndex: objColumn.strDataIndex,
      key: objColumn.strKey || objColumn.strDataIndex,
      sorter: objColumn.bSorter !== false ? {
        compare: (a, b) => {
          const aValue = a[objColumn.strDataIndex];
          const bValue = b[objColumn.strDataIndex];

          // Verificar si los valores son cadenas y convertir a minúsculas
          const aValueStr = typeof aValue === 'string' ? aValue.toLowerCase() : aValue;
          const bValueStr = typeof bValue === 'string' ? bValue.toLowerCase() : bValue;

          // Comparar valores
          if (aValueStr < bValueStr) return -1;
          if (aValueStr > bValueStr) return 1;
          return 0;
        },
      } : undefined,
      class: objColumn.strClass || "",
    }));
  }

  const lstColumnsToCreate = [
    { strTitle: "No. Paciente", strDataIndex: "id_paciente", strKey: "id_paciente", bFilter: true },
    { strTitle: "Nombre", strDataIndex: "nombre_completo", strKey: "nombre_completo", bFilter: true },
    { strTitle: "Genero", strDataIndex: "strGenero", strKey: "strGenero", bFilter: true },
    { strTitle: "Celular", strDataIndex: "telefono_celular", strKey: "telefono_celular", bFilter: true },
    { strTitle: "Correo", strDataIndex: "correo_electronico", bFilter: true },
    { strTitle: "Sucursal", strDataIndex: "strSucursal" },
    { strTitle: "Fecha de Ingreso", strDataIndex: "strFechaCreacion" },
    { strTitle: "", strDataIndex: "action", bSorter: true, strClass: "text-end" },
  ];

  const lstRowSelected = [];
  const lstColumns = createColumns(lstColumnsToCreate);
  const lstData = [];
  export default {
    data() {
      return {
        bSpinner: false,
        strTitle: "Pacientes",
        strSubutitle: "Lista de pacientes",
        strSearchQuery: "",
        lstData,
        lstColumns,
        lstRowSelected,
        lstColumnsToCreate,
      };
    },
    computed: {
      handleFilterData() {
      if (!this.strSearchQuery) return this.lstData;
        const query = this.strSearchQuery.toLowerCase();

        return this.lstData.filter((objData) => {
          return this.lstColumnsToCreate.some((objColumn) => {
            if (!objColumn.bFilter) return false;
            const strValue = objData[objColumn.strDataIndex];
            const strValueSearch = (typeof strValue === 'string' && strValue !== null) ? strValue.toLowerCase() : '';
            return strValueSearch.includes(query);
          });
        });
      },
      handleRowSelection(){
        return {
          selectedRowKeys: this.lstRowSelected,
          onChange: this.handleRowSelectionChange,
        }
      }
    },
    created() {
      this.getPacientes();
    },
    methods: {
      async getPacientes() {
        this.bSpinner = true;
        try {
          const objResponse = await axios.get('/pacientes');
          this.lstData = objResponse.data.map((objPaciente) => ({
            ...objPaciente,
            nombre_completo: `${objPaciente.nombre} ${objPaciente.apellido_paterno} ${objPaciente.apellido_materno}`,
            strGenero : objPaciente.genero?.nombre ?? '',
            strSucursal: objPaciente.sucursal?.nombre ?? '',
            strFechaCreacion: format(new Date(objPaciente.fecha_creacion), 'dd/MM/yyyy HH:mm:ss'),
          }));
        } catch (error) {
          console.error('Error fetching data:', error.message);
          console.error('Error fetching data:', error.response.data);
        }
        this.bSpinner = false;
      },
      handleRowSelectionChange(selectedRowKeys) {
        this.lstRowSelected = selectedRowKeys;
      },
      handleSearch() {
        // Este método se llama automáticamente debido a la propiedad computada `filteredData`
      },
    }
  };
</script>
