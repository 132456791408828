<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
      <PatientList />
    </div>
  </div>
<delete></delete>
</template>
<script>
import PatientList from "./PatientList.vue";

export default {
  data() {
    return {
      strTitle: "Pacientes",
      strSubutitle: "Lista de Pacientes",
      strPath: "/patient",
    };
  },
  components: {
    PatientList,
  },
  created() {
    
  },
  methods: {

  }
};
</script>
