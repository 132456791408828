import axios from '@/axios';
import { createRouter, createWebHistory } from 'vue-router';

import Calendar from '@/views/pages/uiinterface/calendar'
import Basictable from '@/views/pages/uiinterface/table/basictable'
import Datatable from '@/views/pages/uiinterface/table/datatable'
import Tables from '@/views/pages/uiinterface/table/tables.vue'
import Formbasicinput from '@/views/pages/uiinterface/form/formbasic/formbasicinput'
import Forminput from '@/views/pages/uiinterface/form/forminput/forminput'
import FormHorizontal from '@/views/pages/uiinterface/form/formhorizontal/horizontal'
import Formmask from '@/views/pages/uiinterface/form/formmask/formmask'
import Formselect2 from '@/views/pages/uiinterface/form/formselect2'
import Formfileupload from '@/views/pages/uiinterface/form/formfileupload'
import Formvalidation from '@/views/pages/uiinterface/form/formvalidation'
import Formvertical from '@/views/pages/uiinterface/form/formvertical'
import Forms from '@/views/pages/uiinterface/form/forms.vue'
import Iconfontawesome from '@/views/pages/uiinterface/icons/icon-fontawesome'
import Iconfeather from '@/views/pages/uiinterface/icons/icon-feather'
import Iconionic from '@/views/pages/uiinterface/icons/icon-ionic'
import Iconmaterial from '@/views/pages/uiinterface/icons/icon-material'
import Iconpe7 from '@/views/pages/uiinterface/icons/icon-pe7'
import Iconsimpleline from '@/views/pages/uiinterface/icons/icon-simpleline'
import Iconthemify from '@/views/pages/uiinterface/icons/icon-themify'
import Iconweather from '@/views/pages/uiinterface/icons/icon-weather'
import Icontypicon from '@/views/pages/uiinterface/icons/icon-typicon'
import Iconflag from '@/views/pages/uiinterface/icons/icon-flag'
import Icons from '@/views/pages/uiinterface/icons/icons.vue'
import Chartapex from '@/views/pages/uiinterface/chart/apex/chart-apex'
import Chartc3 from '@/views/pages/uiinterface/chart/c3/chart-c3'
import Chartflot from '@/views/pages/uiinterface/chart/flot/chart-flot'
import Chartjs from '@/views/pages/uiinterface/chart/js/chart-js'
import Chartmorris from '@/views/pages/uiinterface/chart/morris/chart-morris'
import Charts from '@/views/pages/uiinterface/chart/charts.vue'
import Ribbon from '@/views/pages/uiinterface/elements/ribbon.vue'
import Rating from '@/views/pages/uiinterface/elements/rating.vue'
import Clipboard from '@/views/pages/uiinterface/elements/clipboard'
import Dragdrop from '@/views/pages/uiinterface/elements/drag-drop'
import Texteditor from '@/views/pages/uiinterface/elements/text-editor'
import Counter from '@/views/pages/uiinterface/elements/counter'
import Scrollbar from '@/views/pages/uiinterface/elements/scrollbar'
import Notificationelement from '@/views/pages/uiinterface/elements/notificationelement'
import Stickynote from '@/views/pages/uiinterface/elements/stickynote'
import Timeline from '@/views/pages/uiinterface/elements/timeline'
import Horizontaltimeline from '@/views/pages/uiinterface/elements/horizontal-timeline'
import Formwizard from '@/views/pages/uiinterface/elements/form-wizard'
import Elements from '@/views/pages/uiinterface/elements/elements.vue'
import AccordionsUi from '@/views/pages/uiinterface/baseui/accordions/accordions'
import AlertsUi from '@/views/pages/uiinterface/baseui/alerts'
import AvatarUi from '@/views/pages/uiinterface/baseui/avatar'
import BadgesUi from '@/views/pages/uiinterface/baseui/badges/badges'
import ButtongroupUi from '@/views/pages/uiinterface/baseui/buttongroup'
import ButtonsUi from '@/views/pages/uiinterface/baseui/buttons'
import BreadcrumbsUi from '@/views/pages/uiinterface/baseui/breadcrumbs'
import CardsUi from '@/views/pages/uiinterface/baseui/cards'
import CarouselUi from '@/views/pages/uiinterface/baseui/carousel/carousel'
import DropdownsUi from '@/views/pages/uiinterface/baseui/dropdowns'
import GridUi from '@/views/pages/uiinterface/baseui/grid'
import ImagesUi from '@/views/pages/uiinterface/baseui/images'
import LightboxUi from '@/views/pages/uiinterface/baseui/lightbox/lightbox'
import MediaUi from '@/views/pages/uiinterface/baseui/media'
import ModalUi from '@/views/pages/uiinterface/baseui/modal/modal'
import OffcanvasUi from '@/views/pages/uiinterface/baseui/offcanvas/offcanvas'
import PaginationUi from '@/views/pages/uiinterface/baseui/pagination'
import ProgressUi from '@/views/pages/uiinterface/baseui/progress/progress'
import PlaceholdersUi from '@/views/pages/uiinterface/baseui/placeholders'
import PopoverUi from '@/views/pages/uiinterface/baseui/popover'
import RangesliderUi from '@/views/pages/uiinterface/baseui/rangeslider'
import SpinnersUi from '@/views/pages/uiinterface/baseui/spinners'
import SweetalertsUi from '@/views/pages/uiinterface/baseui/sweetalerts'
import TabUi from '@/views/pages/uiinterface/baseui/tab/tab'
import ToastrUi from '@/views/pages/uiinterface/baseui/toastr'
import TooltipUi from '@/views/pages/uiinterface/baseui/tooltip'
import TypographyUi from '@/views/pages/uiinterface/baseui/typography'
import VideoUi from '@/views/pages/uiinterface/baseui/video'
import Baseui from '@/views/pages/uiinterface/baseui/baseui.vue'
import Login from '@/views/pages/pages/login.vue'
import Register from '@/views/pages/pages/register.vue'
import Forgot_Paasword from '@/views/pages/pages/forgot-password.vue'
import Change_Password2 from '@/views/pages/pages/change-password2.vue'
import Lock_Screen from '@/views/pages/pages/lock-screen.vue'

//Dashboard
import IndexAdminDashboard from '@/views/pages/dashboard/admin/IndexAdminDashboard.vue'

//Patients
import Patients from '@/views/pages/patients/patients.vue'
import PatientGrid from '@/views/pages/patient/PatientGrid.vue';
import AddPatient from '@/views/pages/patient/AddPatient.vue';
import PatientProfile from '@/views/pages/patient/PatientProfile.vue';
import AddMedicalHistory from '@/views/pages/medical-history/AddMedicalHistory.vue';

//Patology Patient
import AddPatologyPatient from '@/views/pages/patology-patient/AddPatologyPatient.vue';

//Habit Patient
import AddHabitPatient from '@/views/pages/habit-patient/AddHabitPatient.vue';

//Allergy Patient
import AddAllergyPatient from '@/views/pages/allergy-patient/AddAllergyPatient.vue';

//Consultation
import ConsultationGrid from '@/views/pages/consultation-patient/ConsultationGrid.vue'
import AddConsultation from '@/views/pages/consultation-patient/AddConsultation.vue';
import ConsultationDetail from '@/views/pages/consultation-patient/ConsultationDetail.vue';

//Appointment-Patient
import AddAppointmentPatient from '@/views/pages/appointment-patient/AddAppointment.vue';

//Appointment Calendar
import AppointmentCalendar from '@/views/pages/appointment/AppointmentCalendar.vue';

//Appointment
import AppointmentGrid from '@/views/pages/appointment/AppointmentGrid.vue';
import AddAppointmentWizard from '@/views/pages/appointment/AddAppointmentWizard.vue';
//import AddAppointment from '@/views/pages/appointment/AddAppointment.vue';

//Patient Test
import AddPatientTest from '@/views/pages/patient-test/AddPatientTest.vue';

//SettingsTab
import SettingTabOrg from '@/views/pages/settings/SettingTabOrg.vue';
import SettingTabGeneral from '@/views/pages/settings/SettingTabGeneral.vue';
import SettingTabGeo from '@/views/pages/settings/SettingTabGeo.vue';
import SettingTabMedical from '@/views/pages/settings/SettingTabMedical.vue';
import SettingTabSchedule from '@/views/pages/settings/SettingTabSchedule.vue';


//Country
import CountryGrid from '@/views/pages/country/CountryGrid.vue';
import AddCountry from '@/views/pages/country/AddCountry.vue';

//State
import StateGrid from '@/views/pages/state/StateGrid.vue';
import AddState from '@/views/pages/state/AddState.vue';

//City
import CityGrid from '@/views/pages/city/CityGrid.vue';
import AddCity from '@/views/pages/city/AddCity.vue';

//Neighborhood
import NeighborhoodGrid from '@/views/pages/neighborhood/NeighborhoodGrid.vue';
import AddNeighborhood from '@/views/pages/neighborhood/AddNeighborhood.vue';

//Postal Code
import PostalCodeGrid from '@/views/pages/postal-code/PostalCodeGrid.vue';
import AddPostalCode from '@/views/pages/postal-code/AddPostalCode.vue';

//Appointment Type
import AppointmentTypeGrid from '@/views/pages/appointment-type/AppointmentTypeGrid.vue';
import AddAppointmentType from '@/views/pages/appointment-type/AddAppointmentType.vue';

//Gender
import GenderGrid from '@/views/pages/gender/GenderGrid.vue';
import AddGender from '@/views/pages/gender/AddGender.vue';

//Mariatal Status
import MaritalStatusGrid from '@/views/pages/marital-status/MaritalStatusGrid.vue';
import AddMaritalStatus from '@/views/pages/marital-status/AddMaritalStatus.vue';

//Profession
import ProfessionGrid from '@/views/pages/profession/ProfessionGrid.vue';
import AddProfession from '@/views/pages/profession/AddProfession.vue';

//Work Type
import WorkTypeGrid from '@/views/pages/work-type/WorkTypeGrid.vue';
import AddWorkType from '@/views/pages/work-type/AddWorkType.vue';

//Patient Status
import PatientStatusGrid from '@/views/pages/patient-status/PatientStatusGrid.vue';
import AddPatientStatus from '@/views/pages/patient-status/AddPatientStatus.vue';

//Relationship
import RelationshipGrid from '@/views/pages/relationship/RelationshipGrid.vue';
import AddRelationship from '@/views/pages/relationship/AddRelationship.vue';

//Reason visit
import ReasonVisitGrid from '@/views/pages/reason-visit/ReasonVisitGrid.vue';
import AddReasonVisit from '@/views/pages/reason-visit/AddReasonVisit.vue';

//Treatment
import TreatmentGrid from '@/views/pages/treatment/TreatmentGrid.vue';
import AddTreatment from '@/views/pages/treatment/AddTreatment.vue';

//Patology
import PatologyGrid from '@/views/pages/patology/PatologyGrid.vue';
import AddPatology from '@/views/pages/patology/AddPatology.vue';

//Allergy
import AllergyGrid from '@/views/pages/allergy/AllergyGrid.vue';
import AddAllergy from '@/views/pages/allergy/AddAllergy.vue';

//Habit
import HabitGrid from '@/views/pages/habit/HabitGrid.vue';
import AddHabit from '@/views/pages/habit/AddHabit.vue';

//Anatomy
import AnatomyGrid from '@/views/pages/anatomy/AnatomyGrid.vue';
import AddAnatomy from '@/views/pages/anatomy/AddAnatomy.vue';

//Limb
import LimbGrid from '@/views/pages/limb/LimbGrid.vue';
import AddLimb from '@/views/pages/limb/AddLimb.vue';

//Test
import TestGrid from '@/views/pages/test/TestGrid.vue';
import AddTest from '@/views/pages/test/AddTest.vue';

//Company
import CompanyGrid from '@/views/pages/company/CompanyGrid.vue';
import AddCompany from '@/views/pages/company/AddCompany.vue';

//Branch
import BranchGrid from '@/views/pages/branch/BranchGrid.vue';
import AddBranch from '@/views/pages/branch/AddBranch.vue';

//Schedule Status
import ScheduleStatusGrid from '@/views/pages/schedule-status/ScheduleStatusGrid.vue';
import AddScheduleStatus from '@/views/pages/schedule-status/AddScheduleStatus.vue';

//Schedule Category
import ScheduleCategoryGrid from '@/views/pages/schedule-category/ScheduleCategoryGrid.vue';
import AddScheduleCategory from '@/views/pages/schedule-category/AddScheduleCategory.vue';


import Patient_Setting from '@/views/pages/patients/patient-setting/patient-setting.vue'

import Staff from '@/views/pages/staff/staff.vue'
import StaffList from '@/views/pages/staff/staff-list.vue'
import EditStaff from '@/views/pages/staff/edit-staff.vue'
import AddStaff from '@/views/pages/staff/add-staff.vue'
import StaffProfile from '@/views/pages/staff/staff-profile.vue'
import StaffSetting from '@/views/pages/staff/staff-setting.vue'
import StaffLeave from '@/views/pages/staff/staff-leave.vue'
import StaffHoliday from '@/views/pages/staff/staff-holiday.vue'
import StaffAttendance from '@/views/pages/staff/staff-attendance.vue'

import Departments from '@/views/pages/departments/departments.vue'
import DepartmentList from '@/views/pages/departments/department-list.vue'
import AddDepartment from '@/views/pages/departments/add-department.vue'
import EditDepartment from '@/views/pages/departments/edit-department.vue'
import Accounts from '@/views/pages/accounts/accounts.vue'
import Invoices from '@/views/pages/accounts/invoices.vue'
import CreateInvoice from '@/views/pages/accounts/create-invoice.vue'
import EditInvoice from '@/views/pages/accounts/edit-invoice.vue'
import Payments from '@/views/pages/accounts/payments.vue'
import AddPayment from '@/views/pages/accounts/add-payment.vue'
import EditPayment from '@/views/pages/accounts/edit-payment.vue'
import Expenses from '@/views/pages/accounts/expenses.vue'
import AddExpense from '@/views/pages/accounts/add-expense.vue'
import EditExpense from '@/views/pages/accounts/edit-expense.vue'
import Taxes from '@/views/pages/accounts/taxes.vue'
import AddTax from '@/views/pages/accounts/add-tax.vue'
import EditTax from '@/views/pages/accounts/edit-tax.vue'
import ProvidentFund from '@/views/pages/accounts/provident-fund.vue'
import AddProvidentFund from '@/views/pages/accounts/add-provident-fund.vue'
import EditProvidentFund from '@/views/pages/accounts/edit-provident-fund.vue'

import Settings from '@/views/pages/settings/settings.vue'
import General_Settings from '@/views/pages/settings/general-settings.vue'
import Localization_Details from '@/views/pages/settings/localization-details.vue'
import Payment_Settings from '@/views/pages/settings/payment-settings.vue'
import Email_Settings from '@/views/pages/settings/email-settings.vue'
import Social_Settings from '@/views/pages/settings/social-settings.vue'
import Social_Links from '@/views/pages/settings/social-links.vue'
import Seo_Settings from '@/views/pages/settings/seo-settings.vue'
import Theme_Settings from '@/views/pages/settings/theme-settings.vue'
import Change_Password from '@/views/pages/settings/change-password.vue'
import Others_Settings from '@/views/pages/settings/others-settings.vue'
import Assets from '@/views/pages/assets/assets.vue'
import Assets_List from '@/views/pages/assets/assets-list.vue'
import Add_Assets from '@/views/pages/assets/add-assets.vue'
import Edit_Assets from '@/views/pages/assets/edit-assets.vue'
import Payroll from '@/views/pages/payroll/payroll.vue'
import Salary from '@/views/pages/payroll/salary.vue'
import AddSalary from '@/views/pages/payroll/add-salary.vue'
import EditSalary from '@/views/pages/payroll/edit-salary.vue'
import SalaryView from '@/views/pages/payroll/salary-view.vue'
import Chat from '@/views/pages/chat/chat.vue'
import AddLeave from '@/views/pages/staff/add-leave.vue'
import EditLeave from '@/views/pages/staff/edit-leave.vue'
import Activities from '@/views/pages/activities/activities.vue'
import Activities_List from '@/views/pages/activities/activities-list.vue'
import Reports from '@/views/pages/reports/reports.vue'
import Expense_Reports from '@/views/pages/reports/expense-reports.vue'
import Invoice_Reports from '@/views/pages/reports/invoice-reports.vue'
import Profile from '@/views/pages/pages/profile.vue'
import Edit_Profile from '@/views/pages/pages/edit-profile.vue'
import Gallery from '@/views/pages/pages/gallery.vue'
import Error_500 from '@/views/pages/pages/error-500.vue'
import Error_404 from '@/views/pages/pages/error-404.vue'
import Blank_Page from '@/views/pages/pages/blank-page.vue'
import InvoiceView from '@/views/pages/accounts/invoice-view.vue'
import AppointmentPatientList from '@/views/pages/appointment/AppointmentPatientList.vue';


//import { props } from 'vue3-tags-input';

//import AddAppointment from '@/views/pages/appointments/add-appointment.vue';

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/change-password2',
    name: 'change-password2',
    component: Change_Password2
  },
  {
    path: '/forgot-password',
    name: 'forgot-password2',
    component: Forgot_Paasword
  },
  {
    path: '/lock-screen',
    name: 'lock-screen',
    component: Lock_Screen
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/edit-profile',
    name: 'edit-profile',
    component: Edit_Profile
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: Gallery
  },
  
  {
    path: '/error-500',
    name: 'error-500',
    component: Error_500
  },
  {
    path: '/blank-page',
    name: 'blank-page',
    component: Blank_Page
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar
  },

  //Dashboard
  { path: '/dashboard', name: 'dashboard', component: IndexAdminDashboard, children: [
    { path: '', redirect: '/dashboard/admin' },
    { path: 'admin', name: 'index-admin-dashboard', component: IndexAdminDashboard, meta: { requiresAuth: true } },
    ]
  },

  //Appointment
  { path: '/appointment', component: Patients, children: [
      { path: '', redirect: '/appointment/list' },
      { path: 'list', name: 'appointment-list', component: AppointmentGrid, meta: { requiresAuth: true } },
      { path: 'calendar', name: 'calendar', component: AppointmentCalendar, meta: { requiresAuth: true } },
      { path: 'appointment/new', name: 'add-appointment', component: AddAppointmentWizard, meta: { requiresAuth: true } },

      { path: 'appointment-list-patient', name: 'appointment-list-patient', component: AppointmentPatientList, meta: { requiresAuth: true } },
      ]
  }, 

  //Patients
  {
    path: '/patient',
    component: Patients,
    children: [
      { path: '', redirect: '/patient/list' },
      { path: "list", name: "patients-list", component: PatientGrid, meta: { requiresAuth: true } },

      //Patient Profile
      { path: "new", name: "patient-new", component: AddPatient, props: { isEdit: false }, meta: { requiresAuth: true } },
      { path: ":id/edit", name: "patient-edit", component: AddPatient, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },
      { path: ":id/profile", name: 'patient-profile', component: PatientProfile, props: route => ({ id: route.params.id }), meta: { requiresAuth: true } },

      //Patology Patient
      { path: ":id/patology/new", name: 'add-patient-patology', component: AddPatologyPatient, props: route => ({ id: route.params.id }), meta: { requiresAuth: true } },
      { path: ":id/patology/:recordId/edit", name: 'edit-patient-patology', component: AddPatologyPatient, props: route => ({ isEdit: true, id: route.params.id, recordId: route.params.recordId }), meta: { requiresAuth: true } },

      //Allergy Patient
      { path: ":id/allergy/new", name: 'add-patient-allergy', component: AddAllergyPatient, props: route => ({ id: route.params.id }), meta: { requiresAuth: true } },
      { path: ":id/allergy/:recordId/edit", name: 'edit-patient-allergy', component: AddAllergyPatient, props: route => ({ isEdit: true, id: route.params.id, recordId: route.params.recordId }), meta: { requiresAuth: true } },

      //Habit Patient
      { path: ":id/habit/new", name: 'add-patient-habit', component: AddHabitPatient, props: route => ({ id: route.params.id }), meta: { requiresAuth: true } },
      { path: ":id/habit/:recordId/edit", name: 'edit-patient-habit', component: AddHabitPatient, props: route => ({ isEdit: true, id: route.params.id, recordId: route.params.recordId }), meta: { requiresAuth: true } },

      //Medical History
      { path: ":id/medical-history/new", name: 'add-medical-history', component: AddMedicalHistory, props: route => ({ id: route.params.id }), meta: { requiresAuth: true } },
      { path: ":id/medical-history/:recordId/edit", name: 'edit-medical-history', component: AddMedicalHistory, props: route => ({ isEdit: true, id: route.params.id, recordId: route.params.recordId }), meta: { requiresAuth: true } },

      //Consultation
      { path: ":id/medical-history/:parentId/consultation/list", name: 'patient-history-consultation', component: ConsultationGrid, props: route => ({ id: route.params.id, parentId: route.params.parentId }), meta: { requiresAuth: true } },
      { path: ":id/medical-history/:parentId/consultation/new", name: 'add-consultation', component: AddConsultation, props: route => ({ id: route.params.id, parentId: route.params.parentId }), meta: { requiresAuth: true } },
      { path: ":id/medical-history/:parentId/consultation/:recordId/edit", name: 'edit-consultation', component: AddConsultation, props: route => ({ isEdit: true, id: route.params.id, parentId: route.params.parentId, recordId: route.params.recordId }), meta: { requiresAuth: true } },
      { path: ":id/medical-history/:parentId/consultation/:recordId/detail", name: 'detail-consultation', component: ConsultationDetail, props: route => ({ id: route.params.id, parentId: route.params.parentId, recordId: route.params.recordId }), meta: { requiresAuth: true } },

      //Appointment-Patient
      { path: ":id/appoinment/new" , name: 'add-appointment-patient', component: AddAppointmentPatient, props: route => ({ id: route.params.id }), meta: { requiresAuth: true } },
      { path: ":id/appoinment/:recordId/edit" , name: 'edit-appointment-patient', component: AddAppointmentPatient, props: route => ({ isEdit: true, id: route.params.id, recordId: route.params.recordId }), meta: { requiresAuth: true } },

      //Patient Test
      { path: ":id/medical-history/:historyId/consultation/:appointmentId/test/new", name: 'add-patient-test', component: AddPatientTest, props: route => ({ id: route.params.id, historyId: route.params.historyId, appointmentId: route.params.appointmentId }), meta: { requiresAuth: true } },
      { path: ":id/medical-history/:historyId/consultation/:appointmentId/test/:testId/edit", name: 'edit-patient-test', component: AddPatientTest, props: route => ({ isEdit: true, id: route.params.id, historyId: route.params.historyId, appointmentId: route.params.appointmentId, testId: route.params.testId }), meta: { requiresAuth: true } },

      { path: "patient-setting", component: Patient_Setting },
    ]
  },

  //Settings
  {
    path: '/settings',
    component: Settings,
    children: [
      {path: '', redirect: '/settings/settings'},

      {path: 'org', name: 'org', component: SettingTabOrg },
      {path: 'general', name: 'general', component: SettingTabGeneral },
      {path: 'geographic', name: 'geographic', component: SettingTabGeo },
      {path: 'medical', name: 'medical', component: SettingTabMedical },
      {path: 'schedule', name: 'schedule', component: SettingTabSchedule },
      

      //Country
      {path: 'geographic/country', name: 'country', component: CountryGrid, meta: { requiresAuth: true } },
      {path: 'geographic/country/add-country', name: 'add-country', component: AddCountry, meta: { requiresAuth: true } },
      {path: 'geographic/country/edit-country/:id', name: 'edit-country', component: AddCountry, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //State
      {path: 'geographic/state', name: 'state', component: StateGrid, meta: { requiresAuth: true } },
      {path: 'geographic/state/add-state', name: 'add-state', component: AddState, meta: { requiresAuth: true } },
      {path: 'geographic/state/edit-state/:id', name: 'edit-state', component: AddState, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //City
      {path: 'geographic/city', name: 'city', component: CityGrid, meta: { requiresAuth: true } },
      {path: 'geographic/city/add-city', name: 'add-city', component: AddCity, meta: { requiresAuth: true } },
      {path: 'geographic/city/edit-city/:id', name: 'edit-city', component: AddCity, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Neighborhood
      {path: 'geographic/neighborhood', name: 'neighborhood', component: NeighborhoodGrid, meta: { requiresAuth: true } },
      {path: 'geographic/neighborhood/add-neighborhood', name: 'add-neighborhood', component: AddNeighborhood, meta: { requiresAuth: true } },
      {path: 'geographic/neighborhood/edit-neighborhood/:id', name: 'edit-neighborhood', component: AddNeighborhood, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Postal Code
      {path: 'geographic/postal-code', name: 'postal-code', component: PostalCodeGrid, meta: { requiresAuth: true } },
      {path: 'geographic/postal-code/add-postal-code', name: 'add-postal-code', component: AddPostalCode, meta: { requiresAuth: true } },
      {path: 'geographic/postal-code/edit-postal-code/:id', name: 'edit-postal-code', component: AddPostalCode, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Appointment Type
      { path: 'general/appointment-type', name: 'appointment-type', component: AppointmentTypeGrid, meta: { requiresAuth: true } },
      { path: 'general/appointment-type/add-appointment-type', name: 'add-appointment-type', component: AddAppointmentType, meta: { requiresAuth: true } },
      { path: 'general/appointment-type/edit-appointment-type/:id', name: 'edit-appointment-type', component: AddAppointmentType, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Gender
      { path: 'general/gender', name: 'gender', component: GenderGrid, meta: { requiresAuth: true } },
      { path: 'general/gender/add-gender', name: 'add-gender', component: AddGender, meta: { requiresAuth: true } },
      { path: 'general/gender/edit-gender/:id', name: 'edit-gender', component: AddGender, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Marital Status
      { path: 'general/marital-status', name: 'marital-status', component: MaritalStatusGrid, meta: { requiresAuth: true } },
      { path: 'general/marital-status/add-marital-status', name: 'add-marital-status', component: AddMaritalStatus, meta: { requiresAuth: true } },
      { path: 'general/marital-status/edit-marital-status/:id', name: 'edit-marital-status', component: AddMaritalStatus, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Profession
      { path: 'general/profession', name: 'profession', component: ProfessionGrid, meta: { requiresAuth: true } },
      { path: 'general/profession/add-profession', name: 'add-profession', component: AddProfession, meta: { requiresAuth: true } },
      { path: 'general/profession/edit-profession/:id', name: 'edit-profession', component: AddProfession, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Work Type
      { path: 'general/work-type', name: 'work-type', component: WorkTypeGrid, meta: { requiresAuth: true } },
      { path: 'general/work-type/add-work-type', name: 'add-work-type', component: AddWorkType, meta: { requiresAuth: true } },
      { path: 'general/work-type/edit-work-type/:id', name: 'edit-work-type', component: AddWorkType, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Patient Status
      { path: 'general/patient-status', name: 'patient-status', component: PatientStatusGrid, meta: { requiresAuth: true } },
      { path: 'general/patient-status/add-patient-status', name: 'add-patient-status', component: AddPatientStatus, meta: { requiresAuth: true } },
      { path: 'general/patient-status/edit-patient-status/:id', name: 'edit-patient-status', component: AddPatientStatus, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Relationship
      { path: 'general/relationship', name: 'relationship', component: RelationshipGrid, meta: { requiresAuth: true } },
      { path: 'general/relationship/add-relationship', name: 'add-relationship', component: AddRelationship, meta: { requiresAuth: true } },
      { path: 'general/relationship/edit-relationship/:id', name: 'edit-relationship', component: AddRelationship, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Reason Visit
      { path: 'general/reason-visit', name: 'reason-visit', component: ReasonVisitGrid, meta: { requiresAuth: true } },
      { path: 'general/reason-visit/add-reason-visit', name: 'add-reason-visit', component: AddReasonVisit, meta: { requiresAuth: true } },
      { path: 'general/reason-visit/edit-reason-visit/:id', name: 'edit-reason-visit', component: AddReasonVisit, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Treatment
      { path: 'general/treatment', name: 'treatment', component: TreatmentGrid, meta: { requiresAuth: true } },
      { path: 'general/treatment/add-treatment', name: 'add-treatment', component: AddTreatment, meta: { requiresAuth: true } },
      { path: 'general/treatment/edit-treatment/:id', name: 'edit-treatment', component: AddTreatment, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Patology
      { path: 'medical/patology', name: 'patology', component: PatologyGrid, meta: { requiresAuth: true } },
      { path: 'medical/patology/add-patology', name: 'add-patology', component: AddPatology, meta: { requiresAuth: true } },
      { path: 'medical/patology/edit-patology/:id', name: 'edit-patology', component: AddPatology, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Allergy
      { path: 'medical/allergy', name: 'allergy', component: AllergyGrid, meta: { requiresAuth: true } },
      { path: 'medical/allergy/add-allergy', name: 'add-allergy', component: AddAllergy, meta: { requiresAuth: true } },
      { path: 'medical/allergy/edit-allergy/:id', name: 'edit-allergy', component: AddAllergy, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Habit
      { path: 'medical/habit', name: 'habit', component: HabitGrid, meta: { requiresAuth: true } },
      { path: 'medical/habit/add-habit', name: 'add-habit', component: AddHabit, meta: { requiresAuth: true } },
      { path: 'medical/habit/edit-habit/:id', name: 'edit-habit', component: AddHabit, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Anatomy
      { path: 'medical/anatomy', name: 'anatomy', component: AnatomyGrid, meta: { requiresAuth: true } },
      { path: 'medical/anatomy/add-anatomy', name: 'add-anatomy', component: AddAnatomy, meta: { requiresAuth: true } },
      { path: 'medical/anatomy/edit-anatomy/:id', name: 'edit-anatomy', component: AddAnatomy, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Limb
      { path: 'medical/limb', name: 'limb', component: LimbGrid, meta: { requiresAuth: true } },
      { path: 'medical/limb/add-limb', name: 'add-limb', component: AddLimb, meta: { requiresAuth: true } },
      { path: 'medical/limb/edit-limb/:id', name: 'edit-limb', component: AddLimb, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Test
      { path: 'medical/test', name: 'test', component: TestGrid, meta: { requiresAuth: true } },
      { path: 'medical/test/add-test', name: 'add-test', component: AddTest, meta: { requiresAuth: true } },
      { path: 'medical/test/edit-test/:id', name: 'edit-test', component: AddTest, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Company
      { path: 'business/company', name: 'company', component: CompanyGrid, meta: { requiresAuth: true } },
      { path: 'business/add-company', name: 'add-company', component: AddCompany, meta: { requiresAuth: true } },
      { path: 'business/edit-company/:id', name: 'edit-company', component: AddCompany, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Branch
      { path: 'business/branch', name: 'branch', component: BranchGrid, meta: { requiresAuth: true } },
      { path: 'business/add-branch', name: 'add-branch', component: AddBranch, meta: { requiresAuth: true } },
      { path: 'business/edit-branch/:id', name: 'edit-branch', component: AddBranch, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Schedule Status
      { path: 'schedule/schedule-status', name: 'schedule-status', component: ScheduleStatusGrid, meta: { requiresAuth: true } },
      { path: 'schedule//add-schedule-status', name: 'add-schedule-status', component: AddScheduleStatus, meta: { requiresAuth: true } },
      { path: 'business/edit-schedule-status/:id', name: 'edit-schedule-status', component: AddScheduleStatus, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

      //Schedule Category
      { path: 'schedule/schedule-category', name: 'schedule-category', component: ScheduleCategoryGrid, meta: { requiresAuth: true } },
      { path: 'schedule//add-schedule-category', name: 'add-schedule-category', component: AddScheduleCategory, meta: { requiresAuth: true } },
      { path: 'business/edit-schedule-category/:id', name: 'edit-schedule-category', component: AddScheduleCategory, props: route => ({ isEdit: true, id: route.params.id }), meta: { requiresAuth: true } },

    ]
   },

   //Errors
   { path: '/:pathMatch(.*)*', name: 'NotFound', component: Error_404},

 {
  path: '/departments',
  component: Departments,
  children: [
    {path: '', redirect: '/departments/department-list'},
    {path: 'department-list', component: DepartmentList},
    {path: 'add-department', component: AddDepartment},
    {path: 'edit-department', component: EditDepartment},
  ]
 },
 {
  path: '/accounts',
  component: Accounts,
  children: [
    {path: '', redirect: '/accounts/invoices'},
    {path: 'invoices', component: Invoices},
    {path: 'create-invoice', component: CreateInvoice},
    {path: 'edit-invoice', component: EditInvoice},
    {path: 'payments', component: Payments},
    {path: 'add-payment', component: AddPayment},
    {path: 'edit-payment', component: EditPayment},
    {path: 'edit-payment', component: EditPayment},
    {path: 'expenses', component: Expenses},
    {path: 'add-expense', component: AddExpense},
    {path: 'edit-expense', component: EditExpense},
    {path: 'taxes', component: Taxes},
    {path: 'add-tax', component: AddTax},
    {path: 'edit-tax', component: EditTax},
    {path: 'provident-fund', component: ProvidentFund},
    {path: 'add-provident-fund', component: AddProvidentFund},
    {path: 'edit-provident-fund', component: EditProvidentFund},
    {path: 'invoice-view', component: InvoiceView},
  ]
},
{
  path: '/settings1',
  component: Settings,
  children: [
    {path: '', redirect: '/settings/settings'},
    {path: 'settings', component: General_Settings },
    {path: 'localization-details', component: Localization_Details },
    {path: 'payment-settings', component: Payment_Settings },
    {path: 'email-settings', component: Email_Settings },
    {path: 'social-settings', component: Social_Settings },
    {path: 'social-links', component: Social_Links },
    {path: 'seo-settings', component: Seo_Settings },
    {path: 'theme-settings', component: Theme_Settings },
    {path: 'change-password', component: Change_Password },
    {path: 'others-settings', component: Others_Settings },
  ]
 },
 {
  path: '/assets',
  component: Assets,
  children: [
    {path: '', redirect: '/assets/assets-list'},
    {path: 'assets-list', component: Assets_List },
    {path: 'add-asset', component: Add_Assets },
    {path: 'edit-asset', component: Edit_Assets },
  ]
 },
 {
  path: '/payroll',
  component: Payroll,
  children: [
    {path: '', redirect: '/payroll/salary'},
    {path: 'salary', component: Salary},
    {path: 'add-salary', component: AddSalary},
    {path: 'edit-salary', component: EditSalary},
    {path: 'salary-view', component: SalaryView}
  ]
},
{
  path: '/activities',
  component: Activities,
  children: [
    {path: '', redirect: '/activities/activities'},
    {path: 'activities', component: Activities_List },
  ]
 },
 {
  path: '/reports',
  component: Reports,
  children: [
    {path: '', redirect: '/reports/expense-reports'},
    {path: 'expense-reports', component: Expense_Reports },
    {path: 'invoice-reports', component: Invoice_Reports },
  ]
 }
];

export const router = createRouter({
  history: createWebHistory('/'),
  linkActiveClass: 'active',
  routes,
});

router.beforeEach(async (to, from, next) => {
  const strToken = localStorage.getItem('objToken');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!strToken) {
      localStorage.removeItem('token');
      next({ name: 'login' });
    }else{
      try {
        /*
        await axios.get('usuario/verify-token', {
          headers: { 'Authorization': `Bearer ${strToken}` }
        });*/
        next();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        
      } catch (error) {
        next({ name: 'login' });
        localStorage.removeItem('token');
        console.log(error);
      }
    }
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    next();
  }
});
