<template>
  <div class="row">
    <div class="col-lg-4">
      <div class="doctor-personals-grp">
        <div class="card">
          <div class="card-body">
            <div class="heading-detai d-flex justify-content-between align-items-centerl" >
              <h4>Patologias:</h4>
            </div>
            <PatologyPatientProfileList :lstDataParam="lstPatologiasPaciente" :id="id"/>
          </div>
        </div>
      </div>
      <div class="doctor-personals-grp">
        <div class="card">
          <div class="card-body">
            <div class="heading-detai d-flex justify-content-between align-items-centerl" >
              <h4>Habitos:</h4>
            </div>
            <HabitPatientProfileList :lstDataParam="lstHabitosPaciente" :id="id"/>
          </div>
        </div>
      </div>
      <div class="doctor-personals-grp">
        <div class="card">
          <div class="card-body">
            <div class="heading-detai d-flex justify-content-between align-items-centerl" >
              <h4>Alergias:</h4>
            </div>
            <AllergyPatientProfileList :lstDataParam="lstAlergiasPaciente" :id="id"/>
          </div>
        </div>
      </div>
      <!--div class="doctor-personals-grp">
        <div class="card">
          <div class="card-body">
            <div class="heading-detail">
              <h4 class="mb-3">Notas</h4>
              <p>
                Hello I am Smith Bruklin a Gynaecologist in Sanjivni Hospital Surat. I
                love to work with all my hospital staff and seniour doctors.
              </p>
            </div>
            <div class="about-me-list">
              <ul class="list-space">
                <li>
                  <h4>Genero</h4>
                  <span>{{ objPaciente.strGenero }}</span>
                </li>
                <li>
                  <h4>Edad</h4>
                  <span>{{ objPaciente.strEdad }}</span>
                </li>
                <li>
                  <h4>Designation</h4>
                  <span>Engineer</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div-->
    </div>
    
    <div class="col-lg-8">
      <div class="doctor-personals-grp">
        <div class="card">
          <div class="card-body">
            <div class="profile-tabs">
              <ul class="nav nav-tabs nav-tabs-bottom">
                <li class="nav-item">
                  <a class="nav-link active" href="#historial-clinico" data-bs-toggle="tab">Historia</a>
                </li>
                <!--li class="nav-item">
                  <a class="nav-link" href="#bottom-tab2" data-bs-toggle="tab">Profile</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#bottom-tab3" data-bs-toggle="tab">Messages</a>
                </li-->
              </ul>
              <div class="tab-content">
                <div class="tab-pane show active" id="historial-clinico">
                    <MedicalHistoryList :id="id"/>
                </div>
                <div class="tab-pane" id="bottom-tab2">Tab content 2</div>
                <div class="tab-pane" id="bottom-tab3">Tab content 3</div>
              </div>
            </div>  
            <!--div class="hello-park">
              <p>
                Completed my graduation in Gynaecologist Medicine from the well known and
                renowned institution of India – SARDAR PATEL MEDICAL COLLEGE, BARODA in
                2000-01, which was affiliated to M.S. University. I ranker in University
                exams from the same university from 1996-01.
              </p>
              <p>
                Worked as Professor and Head of the department ; Community medicine
                Department at Sterline Hospital, Rajkot, Gujarat from 2003-2015
              </p>
            </div-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "../../../../axios";
  import AllergyPatientProfileList from "../../allergy-patient/AllergyPatientProfileList.vue";
  import HabitPatientProfileList from "../../habit-patient/HabitPatientProfileList.vue";
  import MedicalHistoryList from "../../medical-history/MedicalHistoryList.vue";
  import PatologyPatientProfileList from "../../patology-patient/PatologyPatientProfileList.vue";

  export default {
    props: {
      id: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        strTipoModal: '',
        objSelectedValue: null,
        lstHistorialMedico: [],
        lstPatologiasPaciente: [],
        lstAlergiasPaciente: [],
        lstHabitosPaciente: [],
        lstPatologiaParam: '',
      };
    },
    components: {
      MedicalHistoryList,
      PatologyPatientProfileList,
      HabitPatientProfileList,
      AllergyPatientProfileList
    },
    async created() {
      await this.getCatalogos();
    },
    methods: {
      async getCatalogos() {
        try {
          const objResponse = await axios.get(`/catalogos/pacientes/${this.id}`);
          this.lstAlergiasPaciente = objResponse.data.alergias.map(objAlergia => ({
            ...objAlergia,
            strNombre: objAlergia.alergia?.nombre ?? '',
          }));
          this.lstHabitosPaciente = objResponse.data.habitos.map(objHabito => ({
            ...objHabito,
            strNombre: objHabito.habito?.nombre ?? '',
          }));
          this.lstPatologiasPaciente = objResponse.data.patologias.map(objPatologia => ({
            ...objPatologia,
            strNombre: objPatologia.patologia?.nombre ?? '',
          }));
        } catch (error) {
          console.error(`Error al obtener historial medico: `, error);
        }
      },
      handleNewRecord(strTipo){
        this.strTipoModal = strTipo;
      },
    },
    
  };
</script>