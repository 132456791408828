<template>
  <div class="page-wrapper">
    <div class="content">
      <div class="error-pages">
        <div class="main-wrapper error-wrapper">
          <div class="error-box">
            <img class="img-fluid" src="@/assets/img/error-01.png" alt="Logo" />
            <h3>
              <img class="img-fluid mb-0" src="@/assets/img/icons/danger.svg" alt="Logo" />
              Recurso no encontrado
            </h3>
            <p>Es posible que haya escrito mal la dirección o que la página se haya movido. </p>
            <router-link to="/dashboard" class="btn btn-primary go-home"
              >Inicio</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
